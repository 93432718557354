import React from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Divider } from "@mui/material";
import "./style.scss"

const CustomTable = ({ List = [], columnConfig = [], isDivider = false }) => {

    return (
        <Box className="summary-table-content">
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            columnConfig.length > 0 && columnConfig.map((column, index) => {
                                return (<React.Fragment key={index}>
                                    <TableCell align={column.align}>{column.name}</TableCell>
                                    {(isDivider) && <Divider orientation="vertical" style={{ borderColor: "#c9c5c5"}} flexItem />}
                                </React.Fragment>)
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {List.length > 0 && List.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                {
                                    columnConfig.map((column, colIndex) => (
                                        <React.Fragment key={colIndex}>
                                            <TableCell align={column.align}>{row[column.col]}</TableCell>
                                            {(isDivider) && <Divider orientation="vertical" style={{ borderColor: "transparent"}} flexItem />}
                                        </React.Fragment>
                                    ))
                                }

                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    );
};

export default CustomTable;
