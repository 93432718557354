import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { styled } from '@mui/material/styles';

const StyledDatePicker = styled(DesktopDatePicker)((props) => ({
  '& label.Mui-focused': {
    color: '#0050C3',
    top : 0
  },
  '& span.MuiInputLabel-asterisk' :{
    color: '#FF9800',
  },
  '& .MuiInputLabel-root': {
    color: props?.color === "warning" ? "#FF9800" : props?.color === "error" ? 'rgb(211, 47, 47)' : "#0050C3",
    fontSize: '13px'
  },
  '& .MuiFormControl-root .MuiTextField-root': {
    // top: '-9px',
      width : "100%"
  },
  '& .MuiIconButton-root': {
    color : "#0050C3"
  },
  '& .MuiOutlinedInput-root': {
    height: '41px',
    width : "100%",
    borderRadius: '6px',
    fontSize: '13px',
    color: '#000',
    backgroundColor: '#fff',
    '& fieldset': {
      borderRadius: '8px',
      borderColor: '#0050C3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0050C3',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: '#0050C3', // Border color for hover state
      borderWidth: '1px',    // Border width for hover state
    },
    '&.Mui-disabled fieldset':{
      borderColor: '#0050C3',
    },
    '&.Mui-error fieldset': {
      borderColor: props?.color === "warning" ? "#FF9800" : 'rgb(211, 47, 47)',
    },
  },
  '& .Mui-disabled':{
    // backgroundColor: "rgba(140, 210, 250, 0.3)",
    color : "black"
  },
  '& input.Mui-disabled': {
    color: "green",
    "-webkit-text-fill-color": "#00000099",
  },
  '& label.Mui-disabled': {
    backgroundColor: "transparent",
    color : props.color === "primary" ? '#0050C3' : '#9C27B0',
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: '#0050C3',
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    color: props.color === "primary" ? '#0050C3' : '#9C27B0',
    backgroundColor : "#8CD2FA4D"
  },
  '& .MuiFormHelperText-root.Mui-disabled': {
    backgroundColor: "transparent",
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor:'#fff',
    },
  },
  "& .MuiInputBase-root": {
    letterSpacing:'-0.45px',
    height: '2.125rem',
    paddingRight: '15px'
  }
}));

export default StyledDatePicker;
