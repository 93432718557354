import React, { memo } from 'react';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

const AutoComplete = (props) => {
  const {label, classes} = props;
  return (
    <Autocomplete
      className={`auto-complete-component ${classes}`}
      size="small"
      {...props}

      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

export default memo(AutoComplete);
