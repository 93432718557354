import React, { useState, useEffect } from "react";

//Mui imports
import { Grid } from "@mui/material";
import SummaryPanel from "../../../Components/SummaryPanel";
import TablePanel from "../../../Components/TablePanel";
import DataTable from "../../../Components/DataGrid";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import { useLazyQuery } from "@apollo/client";
import GET_CLIENT_ACTIVITY_FEE from "../../../Graphql/queries/GET_CLIENT_ACTIVITY_FEE";
import jsUtils from "../../../utils/jsUtils";
import dayjs from "dayjs";

const INITIAL_SUMMARY = [
  {
    title: "Count :",
    value: 0,
  },
  {
    title: "Billable Hours :",
    value: " 00.00",
  },
  {
    title: "Non-Billable Hours :",
    value: " 00.00",
  },
  {
    title: "Admin Hours :",
    value: " 00.00",
  },
  {
    title: "Total Hours :",
    value: " 00.00",
  },
  {
    title: "Billable Amount :",
    value: "$ 00.00",
  },
];

export default function FeesTab() {
  const { inquiryTarget } = useInquiryTarget();
  const [clientMatterState, setClientMatterState] = useState(null);
  const [clientActivityFeeList, setclientActivityFeeList] = useState([]);
  const [feeSummary, setFeeSummary] = useState(INITIAL_SUMMARY);
  // eslint-disable-next-line no-unused-vars
  const [getClientActivityFees] = useLazyQuery(
    GET_CLIENT_ACTIVITY_FEE,
    {
      variables: clientMatterState,
      onCompleted: (data) => {
        const ActivityFee = data.getClientActivityFee.clientActivityFee;

        const updatedActivityFee = ActivityFee.map((item) => ({
          ...item,
          billedTotal: item.billedAmount - item.billedDiscount,
        }));

        setclientActivityFeeList(updatedActivityFee);

        if (ActivityFee.length > 0) {
          const accumulatedBillableHour = ActivityFee.reduce((acc, current) => {
            if (current.billable === "Billable") {
              return acc + current.hours;
            } else {
              return acc;
            }
          }, 0);

          const accumulatedNonBillableHour = ActivityFee.reduce(
            (acc, current) => {
              if (current.billable === "NonBillable") {
                return acc + current.hours;
              } else {
                return acc;
              }
            },
            0
          );

          const accumulatedBillableAmount = ActivityFee.reduce(
            (acc, current) => {
              if (current.billable === "Billable") {
                return acc + current.amount;
              } else {
                return acc;
              }
            },
            0
          );

          const accumulatedAdminHours = ActivityFee.reduce((acc, current) => {
            if (current.billable === "Admin") {
              return acc + current.amount;
            } else {
              return acc;
            }
          }, 0);

          const accumulatedNonHour = ActivityFee.reduce((acc, current) => {
            return acc + current.hours;
          }, 0);

          setFeeSummary([
            {
              title: "Count :",
              value: updatedActivityFee.length,
            },
            {
              title: "Billable Hours :",
              value: accumulatedBillableHour.toFixed(2),
            },
            {
              title: "Non-Billable Hours :",
              value: accumulatedNonBillableHour.toFixed(2),
            },
            {
              title: "Admin Hours :",
              value: accumulatedAdminHours.toFixed(2),
            },
            {
              title: "Total Hours :",
              value: accumulatedNonHour.toFixed(2),
            },
            {
              title: "Billable Amount :",
              value: jsUtils.currencyFormatter(accumulatedBillableAmount),
            },
          ]);
        }
      },
    }
  );

  useEffect(() => {
    if (inquiryTarget) {
      let { client, matter, startDate, endDate } = inquiryTarget;

      //if at least client is selected, call the API
      if (client) {
        setClientMatterState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0, //if no matter is selected, pass O as a jobId
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (clientMatterState) {
      getClientActivityFees();
    }
  }, [clientMatterState]);

  return (
    <Grid container spacing={2}>
      <Grid item sx={3} sm={3} md={3} lg={3} xl={2.3}>
        <SummaryPanel List={feeSummary} />
      </Grid>
      <Grid item sx={9} sm={9} md={9} lg={9} xl={9.7}>
        <TablePanel title="Fees">
          <DataTable rows={clientActivityFeeList} columns={columns} />
        </TablePanel>
      </Grid>
    </Grid>
  );
}

const columns = [
  { field: "id", headerName: "ID", width: 100, isCalcutateIcon: false },
  { field: "status", headerName: "Status", isCalcutateIcon: true, width: 100 },
  {
    field: "activityDate",
    headerName: "Date",
    isCalcutateIcon: false,
    width: 120,
    renderCell: (params) => (
      <span className="column-header">{dayjs(params.row.activityDate).format("MM-DD-YYYY")}</span>
    ),
  },
  {
    field: "billable",
    headerName: "Billable",
    isCalcutateIcon: false,
    width: 150,
  },
  {
    field: "employeeCode",
    headerName: "Timekeeper",
    isCalcutateIcon: false,
    width: 150,
  },
  {
    field: "clientCode",
    headerName: "Client",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "jobCode",
    headerName: "Matter",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "taskCode",
    headerName: "Task Code",
    isCalcutateIcon: false,
    width: 150,
  },
  {
    field: "activityCode",
    headerName: "Activity Code",
    isCalcutateIcon: false,
    width: 150,
  },
  {
    field: "hours",
    headerName: "Hours",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">{params.row.hours.toFixed(2)}</span>
    ),
  },
  {
    field: "rate",
    headerName: "Rate",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.currencyFormatter(params.row.rate)}
      </span>
    ),
  },
  {
    field: "amount",
    headerName: "Amount",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.currencyFormatter(params.row.amount)}
      </span>
    ),
  },
  { field: "billId", headerName: "Bill", isCalcutateIcon: true, width: 150 },
  {
    field: "billedHours",
    headerName: "Billed Hours",
    isCalcutateIcon: true,
    width: 180,
    renderCell: (params) => (
      <span className="column-header">{params.row.billedHours.toFixed(2)}</span>
    ),
  },
  {
    field: "billedQuantity",
    headerName: "Billed Quantity",
    isCalcutateIcon: true,
    width: 180,
    renderCell: (params) => (
      <span className="column-header">
        {params.row.billedQuantity.toFixed(2)}
      </span>
    ),
  },
  {
    field: "billedRate",
    headerName: "Billed Rate",
    isCalcutateIcon: true,
    width: 180,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.currencyFormatter(params.row.billedRate)}
      </span>
    ),
  },
  {
    field: "billedAmount",
    headerName: "Billed Amount",
    isCalcutateIcon: true,
    width: 180,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.currencyFormatter(params.row.billedAmount)}
      </span>
    ),
  },
  {
    field: "billedDiscount",
    isCalcutateIcon: true,
    width: 180,
    headerName: "Billed Discount",
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.currencyFormatter(params.row.billedDiscount)}
      </span>
    ),
  },
  {
    field: "billedTotal",
    headerName: "Billed Total",
    isCalcutateIcon: true,
    width: 180,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.currencyFormatter(params.row.billedTotal)}
      </span>
    ),
  },
  {
    field: "description",
    headerName: "Narratives",
    isCalcutateIcon: false,
    width: 170,
  },
];
