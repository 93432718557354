import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { GET_CLIENT_ATTORNEYS } from '../../../Graphql/queries/GET_CLIENT_ATTORNEYS';
import { GET_JOB_ATTORNEYS } from '../../../Graphql/queries/GET_JOB_ATTORNEYS';

const INITIAL_DATA = {
  originators: [],
  responsibles: [],
  relationships: [],
};

const AttorneySection = ({ state }) => {
  const [attorneyData, setAttorneyData] = useState(INITIAL_DATA);

  const [fetchClientAttorneys] = useLazyQuery(GET_CLIENT_ATTORNEYS, {
    variables: { id: state?.clientId },
    onCompleted: (data) => {
      if (data.getClientRecords) {
        const filterRecords =   filterRecord(data.getClientRecords)
        setAttorneyData(filterRecords);
      } else {
        setAttorneyData(INITIAL_DATA);
      }
    },
  });
  const filterRecord = (records) => {
    let obj = {};
      Object.entries(records)?.forEach((record,index) => {
        if(index !== 0) {
        obj[record[0]] =   record[1]?.filter((rec) => {
          const selectedMonth = new Date(rec?.accountingPeriodEnd?.endDate)?.getMonth();
          const selectedYear = new Date(rec?.accountingPeriodEnd?.endDate)?.getFullYear();
          const currentMonth = new Date().getMonth();
          const currentYear = new Date().getFullYear();
          return currentMonth === selectedMonth  && currentYear === selectedYear;
        })
      }
     }) 
     return obj;
  }
  const [fetchJobAttorneys] = useLazyQuery(GET_JOB_ATTORNEYS, {
    variables: { id: state?.jobId },
    onCompleted: (data) => {
      if (data.getJobRecords) {
        const filterRecords =   filterRecord(data.getJobRecords)
        setAttorneyData(filterRecords);
      } else {
        setAttorneyData(INITIAL_DATA);
      }
    },
  });

  useEffect(() => {
    if (state?.jobId) {
      fetchJobAttorneys();
    } else if (state?.clientId) {
      fetchClientAttorneys();
    }
  }, [state, fetchClientAttorneys, fetchJobAttorneys]);
  return (
    <Box className="attorney-section">
      <Box className="attorney-item">
        <Box className="summary-heading">
          <Typography variant="h6">
            Originating Attorney (Current)
          </Typography>
          {/* <Button variant="outlined">Edit</Button> */}
        </Box>
        <Table>
          <TableBody>
            {attorneyData?.originators?.map((originator, index) => (
              <TableRow key={originator?.employeeID}>
                {originator?.code && originator?.description && (
                  <>
                    <TableCell>
                      <Typography>{originator.code}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {originator.description}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {parseFloat(
                          originator?.originationPercent * 100
                        ).toFixed(2)}
                        %
                      </Typography>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <Box className="attorney-item">
        <Box className="summary-heading">
          <Typography variant="h6">
            Responsible Attorneys (Current)
          </Typography>
          {/* <Button variant="outlined">Edit</Button> */}
        </Box>
        <Table>
          <TableBody>
            {attorneyData?.responsibles?.map(
              (responsibles, index) => (
                <TableRow key={responsibles?.employeeID}>
                  {responsibles?.code &&
                    responsibles?.description && ( // Check if code and description are not null
                      <>
                        <TableCell>
                          <Typography>{responsibles.code}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {responsibles.description}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography>
                            {parseFloat(
                              responsibles?.responsiblePercent * 100
                            ).toFixed(2)}
                            %
                          </Typography>
                        </TableCell>
                      </>
                    )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <Box className="attorney-item">
        <Box className="summary-heading">
          <Typography variant="h6">
            Relationship Attorneys (Current)
          </Typography>
          {/* <Button variant="outlined">Edit</Button> */}
        </Box>
        <Table>
          <TableBody>
            {attorneyData?.relationships?.map(
              (relationships, index) => (
                <TableRow key={relationships?.employeeID}>
                  {relationships?.code &&
                    relationships?.description && (
                      <>
                        <TableCell>
                          <Typography>
                            {relationships.code}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {relationships.description}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography>
                            {parseFloat(
                              relationships?.managerPercent * 100
                            ).toFixed(2)}
                            %
                          </Typography>
                        </TableCell>
                      </>
                    )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default AttorneySection;
