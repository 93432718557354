import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Grid,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CssTextField from '../../../../Components/CommonComponent/CssTextField';
import CssMultiTextField from '../../../../Components/CommonComponent/CssMultiTextField';
import GET_JOB_BILL_SUMMARY from '../../../../Graphql/queries/GET_JOB_BILL_SUMMARY';
import GET_CLIENT_BILL_SUMMARY from '../../../../Graphql/queries/GET_CLIENT_BILL_SUMMARY';
import { useLazyQuery } from '@apollo/client';
import jsUtils from '../../../../utils/jsUtils';

const initialData = {
  billThresholds: [
    {
      code: '',
      taxScheduleID: 0,
      interestScheduleID: 0,
      employeeScheduleID: 0,
      taskScheduleID: 0,
      expenseScheduleID: 0,
      evergreenMinimumBalance: 0.0,
      evergreenThreshold: 0.0,
      maxFeeTotalAmount: 0.0,
      maxFeeBillAmount: 0.0,
      maxExpenseTotalAmount: 0.0,
      maxExpenseBillAmount: 0.0,
      billOnHold: 0,
    },
  ],
  billDetails: [
    {
      arStatementReportID: 0,
      billFrequencyID: 0,
      billInstruction: '',
      billOnDemand: false,
      billRemarks: '',
      coverPageReportID: 0,
      draftBillReportID: 0,
      finalBillReportID: 0,
      lockBillOnGeneration: false,
      maximumUnbilledExpense: 0.0,
      maximumUnbilledFee: 0.0,
      maximumWIPAge: 0,
      minimumUnbilledExpense: 0.0,
      minimumUnbilledFee: 0.0,
      printARStatement: false,
      printCoverPage: false,
    },
  ],
  billFrequency: {
    id: 0,
    Description: '',
  },
  draftBillReport: {
    ID: 0,
    description: '',
  },
  coverPageReport: {
    ID: 0,
    description: '',
  },
  arStatementReport: {
    ID: 0,
    description: '',
  },
  finalBillReport: {
    ID: 0,
    description: '',
  },
  taxSchedule: {
    ID: 0,
    Code: '',
  },
  interestSchedule: {
    ID: 0,
    Code: '',
  },
  employeeSchedule: {
    ID: 0,
    Code: '',
  },
  taskSchedule: {
    ID: 0,
    Code: '',
  },
  expenseSchedule: {
    ID: 0,
    Code: '',
  },
};
const BillingModal = ({ open, handleClose, summaryData, id }) => {
  const [state, setState] = useState(initialData);

  const [fetchJobBillSummary] = useLazyQuery(GET_JOB_BILL_SUMMARY, {
    variables: { id: id?.jobId },
    onCompleted: (data) => {
      if (data?.getJobBillSummary) {
        setState(data?.getJobBillSummary);
      } else {
        setState(initialData);
      }
    },
  });

  const [fetchClientBillSummary] = useLazyQuery(
    GET_CLIENT_BILL_SUMMARY,
    {
      variables: { id: id?.clientId },
      onCompleted: (data) => {
        if (data?.getClientBillSummary) {
          setState(data?.getClientBillSummary);
        } else {
          setState(initialData);
        }
      },
    }
  );

  const billThreshold = state.billThresholds?.[0];
  const billDetails = state.billDetails?.[0];
  const billFrequency = state.billFrequency;
  const draftBillReport = state.draftBillReport;
  const coverPageReport = state.coverPageReport;
  const arStatementReport = state.arStatementReport;
  const finalBillReport = state.finalBillReport;
  const taxSchedule = state.taxSchedule;
  const interestSchedule = state.interestSchedule;
  const employeeSchedule = state.employeeSchedule;
  const taskSchedule = state.taskSchedule;
  const expenseSchedule = state.expenseSchedule;

  const Items1 = [
    {
      label: 'Bill Code',
      value: billThreshold.code,
    },
    {
      label: 'Bill Draft Report',
      value: draftBillReport?.description,
    },
    {
      label: 'Bill Coverage Page',
      value: coverPageReport?.description,
    },
    {
      label: 'Bill AR Statement',
      value: arStatementReport?.description,
    },
    {
      label: 'Bill Final Report',
      value: finalBillReport?.description,
    },
    {
      label: 'Bill On Demand',
      value: billDetails?.billOnDemand ? 'Yes' : 'No',
    },
    {
      label: 'Bill Frequency',
      value: billFrequency?.Description,
    },
    {
      label: 'Lock Bill on Generation',
      value: billDetails?.lockBillOnGeneration ? 'Yes' : 'No',
    },
  ];

  const Items = [
    {
      label: 'Tkpr Schedule',
      value: employeeSchedule?.Code
        ? employeeSchedule?.Code
        : 'No Tkpr',
    },
    {
      label: 'Task Schedule',
      value: taskSchedule?.Code ? taskSchedule?.Code : 'None No Task',
    },
    {
      label: 'Expenses Schedule',
      value: expenseSchedule?.Code
        ? expenseSchedule?.Code
        : 'None No Expense',
    },
    {
      label: 'Interest Schedule',
      value: interestSchedule?.Code
        ? interestSchedule?.Code
        : 'No Interest',
    },
    {
      label: 'Tax Schedule',
      value: taxSchedule?.Code ? taxSchedule?.Code : 'No Tax',
    },
  ];

  const BillThresholdItems = [
    {
      label: 'Evergreen min. Balance',
      value: jsUtils.currencyFormatter(
        billThreshold?.evergreenMinimumBalance
      ),
    },
    {
      label: 'Evergreen Threshold',
      value: jsUtils.currencyFormatter(
        billThreshold?.evergreenThreshold
      ),
    },
    { label: 'Max WIP Age', value: billDetails?.maximumWIPAge },
  ];

  const UnbilledItems = [
    {
      label: 'Min. Unbilled Face',
      value: jsUtils.currencyFormatter(
        billDetails?.minimumUnbilledFee
      ),
    },
    {
      label: 'Max. Unbilled Face',
      value: jsUtils.currencyFormatter(
        billDetails?.maximumUnbilledFee
      ),
    },
    {
      label: 'Min. Unbilled Expenses',
      value: jsUtils.currencyFormatter(
        billDetails?.minimumUnbilledExpense
      ),
    },
    {
      label: 'Max. Unbilled Expenses',
      value: jsUtils.currencyFormatter(
        billDetails?.maximumUnbilledExpense
      ),
    },
  ];

  const MultiRowFields = [
    {
      label: 'Bill Remarks',
      value: billDetails?.billRemarks,
    },
    {
      label: 'Bill Instructions',
      value: billDetails?.billInstruction,
    },
  ];

  useEffect(() => {
    if (id?.jobId) {
      fetchJobBillSummary();
    } else if (id?.clientId) {
      fetchClientBillSummary();
    }
  }, [id, fetchJobBillSummary, fetchClientBillSummary]);

  return (
    <Dialog
      className="billing-modal"
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      title="Billing Details"
    >
      <Box className="billing-modal-header">
        <span className="modal-title">Bill Details</span>
        <Box>
          <IconButton onClick={handleClose}>
            <CloseIcon
              className="icons"
              style={{ color: '#0050C3' }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box className="bill-modal-content">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Client/Matter"
              fullWidth
              name="clientMatter"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={summaryData?.clientMatter}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingY: 2 }}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={'GrayText'}
            >
              Bill Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {Items1.map((item, index) => (
                <Grid item xs={12} sm={12} md={3} key={index}>
                  <CssTextField
                    disabled
                    fullWidth
                    label={item.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>

            <Grid paddingTop={2} container spacing={{ xs: 2, md: 2 }}>
              {Items.map((item, index) => (
                <Grid item xs={2.4} sm={12} md={2.4} key={index}>
                  <CssTextField
                    disabled
                    fullWidth
                    label={item.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} sx={{ paddingY: 2 }}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                color={'GrayText'}
              >
                Bill Thresholds
              </Typography>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 2 }}>
              {BillThresholdItems.map((item, index) => (
                <Grid item xs={4} sm={12} md={4} key={index}>
                  <CssTextField
                    disabled
                    fullWidth
                    label={item.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            <Grid paddingTop={2} container spacing={{ xs: 2, md: 2 }}>
              {UnbilledItems.map((item, index) => (
                <Grid item xs={3} sm={12} md={3} key={index}>
                  <CssTextField
                    disabled
                    fullWidth
                    label={item.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            <Grid paddingTop={2} container spacing={{ xs: 2, md: 2 }}>
              {MultiRowFields.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  <CssMultiTextField
                    disabled
                    fullWidth
                    label={item.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default BillingModal;
