import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import ClientMatterSearchBar from "../ClientMatterSearchBar";
import SummaryTab from "../SummaryTab";
import TabNavigator from "../TabNavigator";
import ExpensesTab from "../ExpensesTab";
import ContactTab from "../ContactTab";
import FeesTab from "../FeesTab";
import WIPLedgerTab from "../WIPLedgerTab";
import ApolloContextProvider from "../../../Graphql/ApolloContextProvider";
import { PrimaryTabsOption } from "../TabNavigator/tabOptions";
import ARLedgerTab from "../ARLedgerTab/ARLedgerTab";
import TrustLedgerTab from "../TrustLedgerTab/TrustLedgerTab";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import AdvanceLedger from "../AdvanceLedgerTab";

const tabPanels = [
  {
    component: <SummaryTab />,
    value: 0,
  },
  {
    component: <ContactTab />,
    value: 1,
  },
  {
    component: <FeesTab />,
    value: 2,
  },
  {
    component: <ExpensesTab />,
    value: 3,
  },
  {
    component: <WIPLedgerTab />,
    value: 4,
  },
  {
    component: <ARLedgerTab />,
    value: 5,
  },
  {
    component: <TrustLedgerTab />,
    value: 6,
  },
  {
    component: <AdvanceLedger />,
    value: 7,
  },
];

const Main = () => {
  // eslint-disable-next-line no-unused-vars
  const { inquiryTarget } = useInquiryTarget();
  return (
    <Card style={{ height: "100%" }}>
      <CardContent style={styleContent}>
        <ApolloContextProvider uri="/main/graphql">
          <ClientMatterSearchBar />
        </ApolloContextProvider>
        <Typography style={styleTypography}>
          {inquiryTarget?.client
            ? `${inquiryTarget?.client?.clientDesc} ${inquiryTarget?.matter ? `- ${inquiryTarget.matter.matterDesc}` : " "}`
            : "  "}
        </Typography>
        <ApolloContextProvider uri="/matter-inquiry/graphql">
          <TabNavigator tabPanels={tabPanels} tabsOption={PrimaryTabsOption} scrollButtons={true} />
        </ApolloContextProvider>
      </CardContent>
    </Card>
  );
};

const styleTypography = {
  fontFamily: "Roboto",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "20px",
  height: "20px",
  letterSpacing: "0.14px",
  width: "fit-content",
  marginTop: "20px",
  marginBottom: "20px",
};
const styleContent = {
  height: "790px",
};

export default Main;
