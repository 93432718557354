

const jsUtils = {
  calculateAgedRow(feeAmount, expenseAmount, row) {
    return {
      ...row,
      fee: feeAmount,
      expense: expenseAmount,
      total: feeAmount + expenseAmount
    };
  },
  
  calculateAgedWIPNetTotal(rows) {

    return {
      title: "Net Total :",
      fee: rows[5].fee - rows[6].fee,
      expense: rows[5].expense - rows[6].expense,
      total: rows[5].total - rows[6].total 
    };
  },

  calculateAccountRow(trustAmount, advanceAmount, row) {
    return {
      ...row,
      trust: trustAmount,
      advance: advanceAmount,
    };
  },

}
export default jsUtils;



