import { gql } from '@apollo/client';

const GET_CLIENT_BILL_SUMMARY = gql`
  query GetClientBillsummary($id: Int!) {
    getClientBillSummary(inquiryRequest: { id: $id }) {
      billThresholds {
        id
        active
        administratorEmployeeID
        aliasName
        clientIndustryTypeID
        clientReferralNote
        clientReferralTypeID
        clientTypeID
        code
        collectionEmployeeID
        contactID
        createdByID
        createdDate
        description
        employeeScheduleID
        endDate
        enforceBillableFlag
        enforceParentCustomerRole
        expenseScheduleID
        interestScheduleID
        invoicePaymentTypeID
        isVisible
        jobDefaultID
        limitActivityCode
        limitExpenseCode
        limitTaskCode
        limitExpenseCode
        limitTaskCode
        modifiedByID
        modifiedDate
        officeID
        parentID
        remarks
        requireActivityCode
        requireTaskCode
        roundingFactor
        startDate
        startPeriodID
        supervisorEmployeeID
        supportsExpenseEntry
        supportsFeeEntry
        taskScheduleID
        taxScheduleID
      }
      billDetails {
        id
        activityCodeXRefID
        administratorEmployeeID
        allowMultipayor
        arStatementMethodType
        arStatementReportID
        billExpenseSequenceTypeID
        billFeeSequenceTypeID
        billFrequencyID
        billInstruction
        billOnDemand
        billRemarks
        code
        collectionEmployeeID
        coverPageReportID
        draftBillReportID
        ebhOverride
        ebhPayor
        ebhPayorName
        eBillField01
        eBillField02
        eBillField03
        eBillField04
        eBillField05
        eBillField06
        eBillField07
        eBillField08
        eBillField09
        eBillField10
        eBillSystemNameID
        employeeXRefID
        expenseCodeXRefID
        expenseInstruction
        feeInstruction
        finalBillReportID
        lockBillOnGeneration
        maximumUnbilledExpense
        maximumUnbilledFee
        maximumWIPAge
        minimumUnbilledExpense
        minimumUnbilledFee
        parentID
        printARStatement
        printCoverPage
        supervisorEmployeeID
        taskCodeXRefID
      }
      billFrequency {
        id
        Description
      }
      draftBillReport {
        ID
        description
      }
      coverPageReport {
        ID
        description
      }
      arStatementReport {
        ID
        description
      }
      finalBillReport {
        ID
        description
      }
      taxSchedule {
        ID
        Code
      }
      interestSchedule {
        ID
        Code
      }
      employeeSchedule {
        ID
        Code
      }
      taskSchedule {
        ID
        Code
      }
      expenseSchedule {
        ID
        Code
      }
    }
  }
`;

export default GET_CLIENT_BILL_SUMMARY;
