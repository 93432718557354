import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CustomPaginationExpenseGrid from '../../../Components/CustomPagination/CustomPaginationExpenseGrid';


export default function DataTable({rows}) {

  const columns = [
    {
      field: 'id',
      headerName: 'S. No',
      width: 170,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 220,
      valueGetter: (params) => `${params.row?.firstName || ''} ${params.row?.lastName || ''}` ,
    },
    {
      field: 'default',
      headerName: 'Default',
      width: 160,
      valueGetter: (params) => params.row.contactIsDefault ? 'Yes' : 'No',
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 160,
      valueGetter: (params) => params.row.contactType,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 160,
      valueGetter: (params) => params.row.roleDescription,
    },
    {
      field: 'phone',
      headerName: 'Telephone',
      width: 180,
      valueGetter: (params) => params.row.telephoneNumber,
    },
    {
      field: 'email',
      headerName: 'Email Address',
      width: 290,
      valueGetter: (params) => params.row.emailAddress,
    },
   
  ];

  return (
    <div className="data-grid">
      <DataGrid
        checkboxSelection={false}
        rows={rows}
        columns={columns}
        pagination={true}
        slots={{
          pagination: CustomPaginationExpenseGrid,
        }}
        pageSizeOptions={[5, 10]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
      />
    </div>
  );
}
