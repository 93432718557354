import React from 'react';
import config from '../../config';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';

// Props:
// - uri: The API endpoint URI
const ApolloContextProvider = ({ children, uri }) => {
  const httpLink = createHttpLink({
    uri: `${config.baseUrl}${uri}`, // Use the provided URI as the API endpoint
    fetch, // Use cross-fetch
  });

  const authLink = setContext((_, { headers }) => {
    // Get the authentication token from local storage if it exists
    const token = localStorage.getItem('sid');
    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        sid: token,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      dataIdFromObject: (o) => {
        o.id
          ? `${o.__typename}-${o.id}`
          : `${o.__typename}-${o.cursor}`;
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloContextProvider;
