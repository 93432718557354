import React, { useState } from 'react';
import { Button } from '../../../Components/CommonComponent';
import dayjs from 'dayjs';
import RangePicker from './RangePicker';
import ClientSearch from './ClientSearch';
import MatterSearch from './MatterSearch';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';

export default function ClientMatterSearchBar() {
  const today = dayjs();
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedMatter, setSelectedMatter] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const { setInquiryTarget } = useInquiryTarget();

  function handleSearch(event) {
    event.preventDefault();
    setInquiryTarget({
      client: selectedClient,
      matter: selectedMatter,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
  }

  return (
    <form action="" onSubmit={handleSearch}>
      <div className="client-matter-search-bar">
        <ClientSearch 
          setSelectedClient={setSelectedClient} 
          selectedMatter={selectedMatter} 
          selectedClient={selectedClient}
        />
        <MatterSearch
          selectedClient={selectedClient}
          setSelectedMatter={setSelectedMatter}
          selectedMatter={selectedMatter} 
        />

        <RangePicker
          selectedClient={selectedClient}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          selectedClientMatter={selectedMatter}
          setSelectedStartDate={setSelectedStartDate}
          setSelectedEndDate={setSelectedEndDate}
        />
        <Button type="submit" variant="contained">
          Search
        </Button>
      </div>
    </form>
  );
}
