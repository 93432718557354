import React from "react";
import { DesktopDatePicker as MuiDatePicker} from "@mui/x-date-pickers";
import { styled } from '@mui/system';

function DatePicker(props) {
        return (
                <StyledDatePicker
                    format="MM/DD/YYYY"
                    slotProps={{ textField: { variant: "standard" } }}
                    {...props}              
                />
        )
}

const StyledDatePicker = styled(MuiDatePicker)`
    & .MuiInputBase-root {
        letter-spacing: -0.65px;
        padding: 2px;
        & .MuiButtonBase-root {
            padding: 0;
            padding-right: 5px;
        }
        & .MuiInputBase-input {
            padding: 15px;
            padding-left: 0;
        }
    }
    & .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    & label.Mui-focused {
        color: #0050C3;
        top: 0;
    }
    & span.MuiInputLabel-asterisk {
        color: #FF9800;
    }
    & .MuiInputLabel-root {
        color: "#0050C3";
        font-size: 13px;
    }
    & .MuiFormControl-root .MuiTextField-root {
        width: 100%;
    }
    & .MuiIconButton-root {
        color: #0050C3;
    }
    & .MuiOutlinedInput-root {
        height: 41px;
        width: 100%;
        border-radius: 6px;
        font-size: 13px;
        color: #000;
        background-color: #fff;
    }
    & .MuiOutlinedInput-root fieldset {
        border-radius: 8px;
        border-color: #0050C3;
    }
    & .MuiOutlinedInput-root.Mui-focused fieldset {
        border-color: #0050C3;
        border-width: 1px;
    }
    & .MuiOutlinedInput-root:hover fieldset {
        border-color: #0050C3;
        border-width: 1px;
    }
    & .MuiOutlinedInput-root.Mui-disabled fieldset {
        border-color: '#9C27B0';
    }
    & .MuiOutlinedInput-root.Mui-error fieldset {
        border-color:'rgb(211, 47, 47)';
    }
    & .Mui-disabled {
        color: black;
    }
    & input.Mui-disabled {
        color: green;
        -webkit-text-fill-color: #00000099;
    }
    & label.Mui-disabled {
        background-color: transparent;
        color: '#9C27B0';
    }
    & .MuiInputLabel-root.Mui-disabled {
        color: '#9C27B0';
    }
    & .MuiOutlinedInput-root.Mui-disabled {
        color:'#9C27B0';
        background-color: #8CD2FA4D;
    }
    & .MuiFormHelperText-root.Mui-disabled {
        background-color: transparent;
    }
    input {
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px white inset;
            background-color: #fff;
        }
    }
    & .MuiInputBase-root {
        letter-spacing: -0.45px;
        height: 2.125rem;
        padding-right: 15px;
    }
`;
export default DatePicker;
