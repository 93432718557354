import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomTable from "../../../../Components/Table";
import "./style.scss";
import jsUtils from "../../../../utils/jsUtils";

const HoursTab = ({ data }) => {

    const [hoursRow, setHoursRow] = useState(initialHoursRow);
    const [timekeeperRow, setTimekeeperRow] = useState(initialTimekeeperRow);

    useEffect(() => {
        if (!data) return;
      
        // Destructure data
        const { activity, timekeeperRecaps } = data;

        // Set Hours row
        setHoursRow([
            { ...hoursRow[0], quantity: jsUtils.negativeNumberFormatter(activity[0]?.hoursWorked || 0) },
            { ...hoursRow[1], quantity: jsUtils.negativeNumberFormatter(activity[0]?.hoursWorkedBilled || 0) },
            { ...hoursRow[2], quantity: jsUtils.negativeNumberFormatter(activity[0]?.hoursVariance || 0) },
            { ...hoursRow[3], quantity: jsUtils.negativeNumberFormatter(activity[0]?.hoursBilled || 0), realization: jsUtils.negativeCurrencyFormatter(activity[0]?.hoursBilledRealization || 0) }
        ]);
        
        // Set Timekeeper row
        setTimekeeperRow(timekeeperRecaps.map(r => ({
            name: r.code,
            hour: jsUtils.negativeNumberFormatter(r.hours || 0),
            rate: jsUtils.negativeCurrencyFormatter(r.rate || 0),
            amount: jsUtils.negativeCurrencyFormatter(r.amount || 0)
        })));

    }, [data]);


    return (
        <div className="subtab-summary-container inquery-custom-scroll">
            <Box className="summary-table-content">
                <CustomTable
                    List={hoursRow}
                    columnConfig={hoursColumnConfig}
                />
            </Box>
            <Box className="summary-table-content subtab-hours-table">
                <div className="sub-tab-title">
                    <Typography variant="h5">
                        Timekeeper Recap
                    </Typography>
                </div>
                <CustomTable
                    List={timekeeperRow}
                    columnConfig={timekeeperColumnConfig}
                    isDivider={true}
                />
            </Box>
        </div >
    );
};

export default HoursTab;



//default rows
const initialHoursRow = [
    { hour: "Worked :", quantity: "-", realization: "-" },
    { hour: "Worked (Billed) :", quantity: "-", realization: "-" },
    { hour: "Variance :", quantity: "-", realization: "-" },
    { hour: "Billed :", quantity: "-", realization: "-" },

];
const initialTimekeeperRow = [
    { name: "-", hour: "-", rate: "-", amount: "-" },
];

//columns header

const hoursColumnConfig = [
    { name: "Hours", align: "left", col: "hour" },
    { name: "Quantity", align: "right", col: "quantity" },
    { name: "Realization %", align: "right", col: "realization" },
]

const timekeeperColumnConfig = [
    { name: "Name", align: "left", col: "name" },
    { name: "Hours", align: "center", col: "hour" },
    { name: "Rate", align: "center", col: "rate" },
    { name: "Amount", align: "center", col: "amount" },
]
