import React, { useState, useEffect } from "react";

//Mui imports
import { Grid } from "@mui/material";
import SummaryPanel from "../../../Components/SummaryPanel";
import TablePanel from "../../../Components/TablePanel";
import DataTable from "../../../Components/DataGrid";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import { useLazyQuery } from "@apollo/client";
import GET_WIP_LEDGER from "../../../Graphql/queries/GET_WIP_LEDGER";
import jsUtils from "../../../utils/jsUtils";

import dayjs from "dayjs";

const INITIAL_SUMMARY = [
  {
    title: "Count :",
    value: 0,
  },
  {
    title: "Hours :",
    value: 0,
  },
  {
    title: "Fees :",
    value: "-",
  },
  {
    title: "Expenses :",
    value: "-",
  },
  {
    title: "Balance(ITD) :",
    value: "-",
  },
];

const ledgerTypes = {
  1: "TimeFee",
  2: "TaskFee",
  3: "SimpleFee",
  101: "Expense",
  103: "SimpleExpense",
  default: "Unknown",
};

export default function WIPLedgerTab() {
  const { inquiryTarget } = useInquiryTarget();
  const [rows, setRows] = useState([]);
  const [feeSummary, setFeeSummary] = useState(INITIAL_SUMMARY);

  //TODO: Loading and error state
  // eslint-disable-next-line no-unused-vars
  // Get Client WIP Ledger
  const [getClientWIPLedger] = useLazyQuery(
    GET_WIP_LEDGER,
    {
      variables: {
        clientId: parseInt(inquiryTarget?.client.clientId),
        jobId: parseInt(inquiryTarget?.matter?.matterId || 0),
        startDate: dayjs(inquiryTarget?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(inquiryTarget?.endDate).format("YYYY-MM-DD"),
      },
      onCompleted: (data) => {
        const FEE_LEDGER_TYPES = [1, 2, 3];
        const EXPENSE_LEDGER_TYPES = [101, 103];
        let totalHours = 0;
        let totalExpenses = 0;
        let totalFees = 0;
        let totalCount = 0;
        let totalBalance = 0;

        if (data && data.getWIPLedger && data.getWIPLedger.wipLedgers) {
          const wipLedgers = data.getWIPLedger.wipLedgers.map((ledger) => {
            return {
              ...ledger,
              type: ledgerTypes[ledger.ledgerTypeID] || ledgerTypes.default,
            };
          });
          setRows(wipLedgers);
          totalCount = wipLedgers.length;
          wipLedgers.forEach((ledger) => {
            totalHours += ledger.hours;
            totalBalance += ledger.balance;
            if (FEE_LEDGER_TYPES.includes(ledger.ledgerTypeID)) {
              totalFees += ledger.amount;
            } else if (EXPENSE_LEDGER_TYPES.includes(ledger.ledgerTypeID)) {
              totalExpenses += ledger.amount;
            }
          });
        }
        setFeeSummary([
          {
            title: "Count :",
            value: totalCount,
          },
          {
            title: "Hours :",
            value: totalHours.toFixed(2),
          },
          {
            title: "Fees :",
            value: jsUtils.negativeCurrencyFormatter(totalFees),
          },
          {
            title: "Expenses :",
            value: jsUtils.negativeCurrencyFormatter(totalExpenses),
          },
          {
            title: "Balance(ITD) :",
            value: jsUtils.negativeCurrencyFormatter(totalBalance),
          },
        ]);
      },
    }
  );

  // Get Client WIP Ledger when inquiry target change
  useEffect(() => {
    if (inquiryTarget) {
      getClientWIPLedger();
    }
  }, [inquiryTarget]);

  return (
    <Grid container spacing={2}>
      <Grid item sx={3} sm={3} md={3} lg={3} xl={2.3}>
        <SummaryPanel List={feeSummary} />
      </Grid>
      <Grid item sx={9} sm={9} md={9} lg={9} xl={9.7}>
        <TablePanel title="WIP Ledger">
          <DataTable rows={rows} columns={columns} />
        </TablePanel>
      </Grid>
    </Grid>
  );
}

const columns = [
  { field: "id", headerName: "ID", width: 100, isCalcutateIcon: false },
  { field: "status", headerName: "Status", isCalcutateIcon: true, width: 100 },
  {
    field: "ledgerDate",
    headerName: "Date",
    isCalcutateIcon: false,
    width: 120,
    renderCell: (params) => (
      <span className="column-header">
        {dayjs(params.row.ledgerDate).format("MM-DD-YYYY")}
      </span>
    ),
  },
  { field: "type", headerName: "Type", isCalcutateIcon: false, width: 150 },
  {
    field: "billable",
    headerName: "Billable",
    isCalcutateIcon: false,
    width: 150,
  },
  {
    field: "clientCode",
    headerName: "Client",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "jobCode",
    headerName: "Matter",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "hours",
    headerName: "Hours",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">{params.row.hours.toFixed(2)}</span>
    ),
  },
  {
    field: "quantity",
    headerName: "Quantity",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">{params.row.quantity.toFixed(2)}</span>
    ),
  },
  {
    field: "rate",
    headerName: "Rate",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.negativeCurrencyFormatter(params.row.rate)}
      </span>
    ),
  },
  {
    field: "amount",
    headerName: "Amount",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.negativeCurrencyFormatter(params.row.amount)}
      </span>
    ),
  },
  {
    field: "balance",
    headerName: "Balance",
    isCalcutateIcon: true,
    width: 180,
    renderCell: (params) => (
      <span className="column-header"> {jsUtils.negativeCurrencyFormatter(params.row.balance)}</span>
    ),
  },
  {
    field: "description",
    headerName: "Remarks",
    isCalcutateIcon: false,
    width: 220,
  },
];
