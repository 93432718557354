import { gql } from '@apollo/client';

export const GET_TRUST_LEDGER_INQUIRY = gql`
  query GetTrustLedgerInquiry($clientId: Int!, $jobId: Int, $startDate: String, $endDate: String) {
    getInquiryLedgerTrust(
      inquiryLedgerTrustRequest: { clientId: $clientId, jobId: $jobId, startDate: $startDate, endDate: $endDate }
    ) {
      balance
      amount
      count
      inquiryLedgerTrust {
        id
        customerId
        bankAccountID
        bankAccountCode
        clientCode
        jobCode
        clientDescription
        jobDescription
        billPaymentId
        invoicePaymentId
        ledgerTypeId
        ledgerDate
        cleared
        amount
        balance
        checkNumber
        payee
        payor
        description
        entryPaymentTypeId
      }
    }
  }
`;
