import { gql } from '@apollo/client';

const GET_DIARY = gql`
  query GetDiary($id: Int!) {
    getDiary(inquiryRequest: { id: $id }) {
      ID
      EmployeeID
      MessageDate
      MessageText
      ParentID
    }
  }
`;

export default GET_DIARY;
