import React from "react";
import { Button as MuiButton } from "@mui/material";
import {styled} from "@mui/system";

const StyledButton = styled(MuiButton)``;

function Button(props) {
  return <StyledButton {...props} />
}

export default Button;

