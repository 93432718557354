import React from 'react';
import "./style.scss";
import { Typography,Box } from '@mui/material';

export default function TablePanel({ title, children }) {
    return (
        <div className='table-panel'>
            <Box className="table-panel-header">
                <Typography variant="h6" paddingLeft={1}>
                    {title}
                </Typography>
            </Box>
            <Box>
                {children}
            </Box>
        </div>
    );
}
