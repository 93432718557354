import { gql } from '@apollo/client';

export const GET_CONTACTS_BY_CLIENT_ID = gql`
query GetContactsByClient($clientId: Int!) {
    getContactsByClient(customerContactRequest: {
          clientId: $clientId
          })
          {
          customerContacts {
              clientId
              jobId
              contactId
              parentId
              roleId
              roleDescription
              contactType
              geoLocationCode
              firstName
              middleInitial
              lastName
              address
              addressIsDefault
              telephoneNumber
              telephoneIsDefault
              emailAddress
              emailAddressIsDefault    
              contactIsDefault        
          }
      }
  }
`;
