import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { onAccountTabState } from "./utils/initialStates";
import jsUtilsSubTab from "./utils/jsUtils";
import jsUtils from "../../../../utils/jsUtils";

const { calculateAccountRow } = jsUtilsSubTab;

const OnAccountTab = ({ data }) => {
  const [clientAccount, setClientAccount] = useState(onAccountTabState);
  const [matterAccount, setMatterAccount] = useState(onAccountTabState);

  useEffect(() => {
    const processAccountData = (trusts, advances, setAccount) => {
      // Set account
      setAccount((prevRow) => {
        const updatedRows = prevRow.map((row) => {
          switch (row.title) {
            case "0 - 30 days :":
              return calculateAccountRow(trusts.trustAmountAge1, advances.advanceAmountAge1, row);
            case "31 - 60 days :":
              return calculateAccountRow(trusts.trustAmountAge2, advances.advanceAmountAge2, row);
            case "61 - 90 days :":
              return calculateAccountRow(trusts.trustAmountAge3, advances.advanceAmountAge3, row);
            case "91 - 120 days :":
              return calculateAccountRow(trusts.trustAmountAge4, advances.advanceAmountAge4, row);
            case "Over 120 days :":
              return calculateAccountRow(trusts.trustAmountAge5, advances.advanceAmountAge5, row);
            case "Total :":
              return calculateAccountRow(trusts.trustAmountTotal, advances.advanceAmountTotal, row);
            default:
              return row;
          }
        });
        return updatedRows;
      });
    };

    if (data && data.clientAdvance[0] && data.clientTrust[0]) {
      const advances = data.clientAdvance[0];
      const trusts = data.clientTrust[0];
      processAccountData(trusts, advances, setClientAccount);
    }

    if (data && data.matterAdvance[0] && data.matterTrust[0]) {
      const advances = data.matterAdvance[0];
      const trusts = data.matterTrust[0];
      processAccountData(trusts, advances, setMatterAccount);
    }
  }, [data]);

  return (
    <Box className="summary-table-content">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Client On Account</TableCell>
            <TableCell align="right">Trust</TableCell>
            <TableCell align="right">Advances</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientAccount.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="left">{item.title}</TableCell>
                <TableCell align="right">{jsUtils.negativeCurrencyFormatter(item.trust)}</TableCell>
                <TableCell align="right">{jsUtils.negativeCurrencyFormatter(item.advance)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Matter On Account</TableCell>
            <TableCell align="right">Trust</TableCell>
            <TableCell align="right">Advances</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matterAccount.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="left">{item.title}</TableCell>
                <TableCell align="right">{jsUtils.negativeCurrencyFormatter(item.trust)}</TableCell>
                <TableCell align="right">{jsUtils.negativeCurrencyFormatter(item.advance)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default OnAccountTab;
