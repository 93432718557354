import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { AgedApTabState } from './utils/initialStates';
import jsUtils from '../../../../utils/jsUtils';

const AgedApTab = ({ data }) => {
  const [agedApData, setAgedAp] = useState(AgedApTabState);

  useEffect(() => {
    if (data) {
      // Set Aged AP
      setAgedAp((prevRows) => {
        const updatedRows = prevRows.map((row) => {
          switch (row.title) {
            case '0 - 30 days :':
              return {
                ...row,
                fee: data.feeAmountAge1,
                expense: data.expenseAmountAge1,
                total: data.arAmountAge1,
              };
            case '31 - 60 days :':
              return {
                ...row,
                fee: data.feeAmountAge2,
                expense: data.expenseAmountAge2,
                total: data.arAmountAge2,
              };
            case '61 - 90 days :':
              return {
                ...row,
                fee: data.feeAmountAge3,
                expense: data.expenseAmountAge3,
                total: data.arAmountAge3,
              };
            case '91 - 120 days :':
              return {
                ...row,
                fee: data.feeAmountAge4,
                expense: data.expenseAmountAge4,
                total: data.arAmountAge4,
              };
            case 'Over 120 days :':
              return {
                ...row,
                fee: data.feeAmountAge5,
                expense: data.expenseAmountAge5,
                total: data.arAmountAge5,
              };
            case 'Total :':
              return {
                ...row,
                fee: data.feeAmountTotal,
                expense: data.expenseAmountTotal,
                total: data.arAmountTotal,
              };
            default:
              return row;
          }
        });

        return updatedRows;
      });
    }
  }, [data]);

  return (
    <Box className="summary-table-content">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Aged AR</TableCell>
            <TableCell align="right">Fees</TableCell>
            <TableCell align="right">Expenses</TableCell>
            <TableCell align="right">Total *</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agedApData.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="left">{item.title}</TableCell>
                <TableCell align="right">
                  {jsUtils.negativeCurrencyFormatter(item.fee)}
                </TableCell>
                <TableCell align="right">
                  {jsUtils.negativeCurrencyFormatter(item.expense)}
                </TableCell>
                <TableCell align="right">
                  {jsUtils.negativeCurrencyFormatter(item.total)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box className="info-content-box">
        *Aged AR Total included interest, surcharges, takes and
        retainers where applicable.
      </Box>
    </Box>
  );
};

export default AgedApTab;
