import { gql } from '@apollo/client';

export const GET_ADVANCE_LEDGER_INQUIRY = gql`
  query getInquiryLedgerAdvance($clientId: Int!, $jobId: Int, $startDate: String, $endDate: String) {
    getInquiryLedgerAdvance(
      inquiryLedgerRequest: { clientId: $clientId, jobId: $jobId, startDate: $startDate, endDate: $endDate }
    ) {
      balance
      amount
      count
      inquiryLedgerDto {
        Id
        ledgerType
        description
        clientDescription
        jobDescription
        balance
        billPaymentID
        payee
        advanceRetainerAmount
        amount
        entryPayor
        customerID
        ledgerDate
        balance
        billPaymentID
        billNumber
        clientCode
        jobCode
      }
    }
  }
`;
