import { gql } from '@apollo/client';

export const GET_AR_LEDGER_INQUIRY = gql`
  query GetARLedgerInquiry(
    $clientId: Int!
    $jobId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getLedgerARInquiry(
      clientId: $clientId
      jobId: $jobId
      startDate: $startDate
      endDate: $endDate
    ) {
      Count
      Amount
      Balance
      ExpenseAmount
      FeeAmount
      InterestAmount
      RetainerAmount
      SurchargeAmount
      TaxAmount
      LedgerARInquiryList {
        Id
        CustomerID
        BillNumber
        BillPaymentID
        BillAdjustmentID
        LedgerType
        LedgerDate
        ClientCode
        JobCode
        ClientDescription
        JobDescription
        FeeAmount
        ExpenseAmount
        InterestAmount
        SurchargeAmount
        TaxAmount
        RetainerAmount
        Amount
        Balance
        Description
      }
    }
  }
`;
