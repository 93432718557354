import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import store from './store';
import { Box } from '@mui/material';
import Main from './pages/MatterInquiry/Main';
import './styles.scss';
import './styles/main.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InquiryTargetProvider } from './hooks/useInquiryTarget';

const App = () => {
  return (
    <InquiryTargetProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <Box className="inquiry-app">
            <Main />
          </Box>
        </Provider>
      </LocalizationProvider>
    </InquiryTargetProvider>
  );
};

export default App;
