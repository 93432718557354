import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const DiaryCustomTextField = styled(TextField)((props) => ({
  '& label.Mui-focused': {
    color: '#0050C3',
    top: 0,
  },
  '& label.Mui-error': {
    color: '#d32f2f',
  },
  '& span.MuiInputLabel-asterisk': {
    color: '#FF9800',
  },
  '& .MuiInputLabel-root': {
    // top: '-9px',
    color: '#0050C3',
    fontSize: props?.style?.fontSize || '20px',
  },
  '& .MuiTextField-root': {
    // top: '-9px',
    width: '100%',
  },
  '& .MuiInputLabel-root.Mui-error': {
    color:
      props?.color === 'warning'
        ? '#FF9800'
        : props?.color === 'error'
        ? 'rgb(211, 47, 47)'
        : '#0050C3',
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color:
      props?.color === 'warning'
        ? '#FF9800'
        : props?.color === 'error'
        ? 'rgb(211, 47, 47)'
        : '#0050C3',
  },
  '& .MuiOutlinedInput-root': {
    paddingRight: '4px',
    height: '8rem',
    borderRadius: '6px',
    backgroundColor: '#fff',
    fontSize: '20px',
    '& fieldset': {
      borderRadius: '8px',
      borderColor: '#0050C3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0050C3',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: '#0050C3', // Border color for hover state
      borderWidth: '1px', // Border width for hover state
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#0050C3',
    },
    '&.Mui-error fieldset': {
      borderColor:
        props?.color === 'warning' ? '#FF9800' : 'rgb(211, 47, 47)',
    },
  },
  '& .Mui-disabled': {
    // backgroundColor: "rgba(140, 210, 250, 0.3)",
    color: 'black',
  },
  '& input.Mui-disabled': {
    color: '#000000',
    // "-webkit-text-fill-color": "#000000",
  },
  '& label.Mui-disabled': {
    backgroundColor: 'transparent',
    color: '#0050C3',
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    color: '#0050C3',
    backgroundColor: '#8CD2FA4D',
  },
  '& .MuiFormHelperText-root.Mui-disabled': {
    backgroundColor: 'transparent',
  },
  input: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      backgroundColor: '#fff',
    },
  },
}));

export default DiaryCustomTextField;
