import React, {createContext, useContext, useState} from "react";
const InquiryTargetContext = createContext();

export const InquiryTargetProvider = ({children}) => {
    const [inquiryTarget, setInquiryTarget] = useState(null)

    return (
        <InquiryTargetContext.Provider value={{inquiryTarget, setInquiryTarget}}>
            {children}
        </InquiryTargetContext.Provider>
    );
};

export const useInquiryTarget = () => useContext(InquiryTargetContext);
