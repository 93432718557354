import React, { useEffect, useState } from "react";

import DataTable from "../../../Components/DataGrid";
import { GET_TRUST_LEDGER_INQUIRY } from "../../../Graphql/queries/GET_TRUST_LEDGER";
import { Grid } from "@mui/material";
import SummaryPanel from "../../../Components/SummaryPanel";
import TablePanel from "../../../Components/TablePanel";
import dayjs from "dayjs";
import jsUtils from "../../../utils/jsUtils";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import { useLazyQuery } from "@apollo/client";

const INITIAL_SUMMARY = [
  {
    title: "Count :",
    value: 0,
  },
  {
    title: "Amount :",
    value: "$ 00.00",
  },
  {
    title: "Balance(ITD) :",
    value: "$ 00.00",
  },
];

const TrustLedgerTab = () => {
  const [rows, setRows] = useState([]);
  const { inquiryTarget } = useInquiryTarget();
  console.log(inquiryTarget, "target");
  const [state, setState] = useState(null);
  const [Summary, setSummary] = useState(INITIAL_SUMMARY);

  const [fetchTrustLedgerInquiry, { data }] = useLazyQuery(
    GET_TRUST_LEDGER_INQUIRY,
    {
      variables: state,
      onCompleted: (data) => {
        console.log("Query completed:", data);
        if (
          data.getInquiryLedgerTrust &&
          data.getInquiryLedgerTrust.inquiryLedgerTrust
        ) {
          const rows = data.getInquiryLedgerTrust.inquiryLedgerTrust;
          setRows(rows);

          setSummary([
            {
              title: "Count :",
              value: data.getInquiryLedgerTrust.count,
            },
            {
              title: "Amount :",
              value: jsUtils.negativeCurrencyFormatter(
                data.getInquiryLedgerTrust.amount
              ),
            },
            {
              title: "Balance(ITD) :",
              value: jsUtils.negativeCurrencyFormatter(
                data.getInquiryLedgerTrust.balance
              ),
            },
          ]);
        } else {
          setRows([]);
          setSummary(INITIAL_SUMMARY);
        }
      },
    }
  );

  useEffect(() => {
    if (inquiryTarget) {
      const { client, matter, startDate, endDate } = inquiryTarget;
      if (client) {
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (state) {
      fetchTrustLedgerInquiry();
    }
  }, [state]);

  useEffect(() => {
    if (data) {
      console.log("Received data:", data);
    }
  }, [data]);

  return (
    <Grid container spacing={2} data-testid="Trust-ledger-container">
      <Grid item xs={12} md={3}>
        <SummaryPanel List={Summary} data-testid="summary-panel"/>
      </Grid>
      <Grid item xs={12} md={9}>
        <TablePanel title="Trust Ledger" data-testid="table-panel">
          <DataTable rows={rows} columns={columns} />
        </TablePanel>
      </Grid>
    </Grid>
  );
};

export default TrustLedgerTab;

const columns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "date",
    headerName: "Date",
    width: 120,
    valueGetter: (params) => dayjs(params.row.ledgerDate).format('MM-DD-YYYY'),
  },
  {
    field: "type",
    headerName: "Type",
    width: 130,
    valueGetter: (params) => params.row.entryPaymentTypeId,
  },
  {
    field: "bankAccount",
    headerName: "Bank Account",
    width: 130,
    valueGetter: (params) => params.row.bankAccountCode,
  },
  {
    field: "client",
    headerName: "Client",
    width: 130,
    valueGetter: (params) => params.row.clientCode,
  },
  {
    field: "matter",
    headerName: "Matter",
    width: 130,
    valueGetter: (params) => params.row.jobCode,
  },
  {
    field: "checkNumber",
    width: 140,
    headerName: "Check Number",
    valueGetter: (params) => params.row.checkNumber,
  },
  {
    field: "paymentType",
    width: 140,
    headerName: "Payment Type",
    valueGetter: (params) => params.row.entryPaymentTypeId,
  },
  {
    field: "payee",
    width: 140,
    headerName: "Payee",
    valueGetter: (params) => params.row.payee,
  },
  {
    field: "payor",
    width: 140,
    headerName: "Payor",
    valueGetter: (params) => params.row.payor,
  },
  {
    field: "amount",
    width: 140,
    headerName: "Amount",
    valueGetter: (params) => jsUtils.negativeCurrencyFormatter(params.row.amount),
  },
  {
    field: "balance",
    width: 140,
    headerName: "Balance",
    valueGetter: (params) => jsUtils.negativeCurrencyFormatter(params.row.balance),
  },
  {
    field: "cleared",
    width: 170,
    headerName: "Cleared",
    valueGetter: (params) => jsUtils.negativeCurrencyFormatter(params.row.cleared),
  },
  {
    field: "remark",
    width: 300,
    headerName: "Remark",
    valueGetter: (params) => params.row.description,
  },
];
