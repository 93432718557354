import { gql } from '@apollo/client';

export const GET_CONTACTS_BY_MATTER_ID = gql`
query GetContactsByMatter($jobId: Int!) {
    getMatterInfoInquiry(jobId: $jobId) {
      clientId
      jobId
      contactId
      parentId
      roleId
      roleDescription
      contactType
      geoLocationCode
      contactIsDefault
      address
      firstName
      middleInitial
      lastName
      addressIsDefault
      telephoneNumber
      telephoneIsDefault
      emailAddress
      emailAddressIsDefault
    }
  }
`;
