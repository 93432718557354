import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import TabNavigator from '../TabNavigator';
import { SummaryTabsOption } from '../TabNavigator/tabOptions';
import Summary from './SubTabs/SummaryTab';
import AgedApTab from './SubTabs/AgedApTab';
import OnAccountTab from './SubTabs/OnAccountTab';
import HoursTab from './SubTabs/HoursTab';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import dayjs from 'dayjs';
import FETCH_SUMMARY_SUBTABS_DATA from '../../../Graphql/queries/FETCH_SUMMARY_SUBTABS_DATA';
import { useLazyQuery } from '@apollo/client';
import ActivityTab from './SubTabs/ActivityTab';
import SummarySection from './SummarySection';
import AgedWIPTab from './SubTabs/AgedWIPTab';
import './style.scss';
import AttorneySection from './AttorneySection';

export default function SummaryTab() {
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);
  const [summaryTabData, setSummaryTabData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [fetchSummarydata, { loading }] = useLazyQuery(
    FETCH_SUMMARY_SUBTABS_DATA,
    {
      variables: state,
      onCompleted: (data) => {
        if (data.getInquirySummary) {
          setSummaryTabData(data.getInquirySummary);
        } else {
          setSummaryTabData(null);
        }
      },
    }
  );

  useEffect(() => {
    if (inquiryTarget) {
      let { client, matter, startDate, endDate } = inquiryTarget;

      //if at least client is selected, call the API
      if (client) {
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0, //if no matter is selected, pass O as a jobId
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (state) {
      fetchSummarydata();
    }
  }, [state]);

  const tabPanels = [
    {
      component: (
        <Summary data={summaryTabData} isLoading={loading} />
      ),
      value: 0,
    },
    {
      component: (
        <HoursTab data={summaryTabData} isLoading={loading} />
      ),
      value: 1,
    },
    {
      component: (
        <ActivityTab
          data={summaryTabData?.activity[0] || null}
          isLoading={loading}
        />
      ),
      value: 2,
    },
    {
      component: (
        <AgedWIPTab
          data={summaryTabData?.agedWIP[0] || null}
          isLoading={loading}
        />
      ),
      value: 3,
    },
    {
      component: (
        <AgedApTab
          data={summaryTabData?.agedAR[0] || null}
          isLoading={loading}
        />
      ),
      value: 4,
    },
    {
      component: (
        <OnAccountTab data={summaryTabData} isLoading={loading} />
      ),
      value: 5,
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item sm={3} md={3} lg={3}>
        <SummarySection id={state} data={summaryTabData} />
      </Grid>

      <Grid item sm={5} md={5} lg={5}>
        <Box className="summary-navigation-box">
          <TabNavigator
            tabPanels={tabPanels}
            tabsOption={SummaryTabsOption}
          />
          <Divider></Divider>
        </Box>
      </Grid>

      <Grid item sm={4} md={4} lg={4}>
        <AttorneySection state={state} />
      </Grid>
    </Grid>
  );
}
// eslint-disable-next-line no-unused-vars
const NavigatorStyle = {
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderRadius: '6px',
  height: '560px',
  padding: '0 10px',
  margin: 0,
};
