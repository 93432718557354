import React, { useState, useEffect } from 'react';
import {
  CssTextField,
  MainCard,
  CssMultiTextField,
  StyledLoadingButton,
} from '../../../Components/CommonComponent';
import { Grid, Stack } from '@mui/material';
import StyledDatePicker from '../../../Components/CommonComponent/StyledDatePicker';

import dayjs from 'dayjs';
import BillingModal from './BillingModal';
import DiaryModal from './DiaryModal';

const INITIALSTATE = {
  address: '',
  contact: '',
  emailAddress: '',
  remarks: '',
  startDate: dayjs(new Date()),
  endDate: dayjs(new Date()),
  telephoneNumber: '',
  type: '',
  clientMatter: '',
  billAttorney: '',
  billType: '',
};

export default function SummarySection({ data, id }) {
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);
  const [isDiaryModalOpen, setIsDiaryModalOpen] = useState(false);
  const [state, setState] = useState(INITIALSTATE);

  useEffect(() => {
    if (data && data.summary && data.summary.length > 0) {
      const summary = data.summary[0];
      setState({
        ...state,
        address: summary.address ?? '',
        type: summary.type ?? '',
        emailAddress: summary.emailAddress ?? '',
        telephoneNumber: summary.telephoneNumber ?? '',
        clientMatter: `${data.client}${
          data.matter ? ` - ${data.matter}` : ''
        }`,
        billAttorney: data.billAttorney ?? '',
        billType: data.billTypeSplit ?? '',
        contact: `${summary.contactFirstName ?? ''} - ${summary.contactLastName ?? ''}`,
        startDate: dayjs(new Date(summary.startDate)),
        endDate: null,
        remarks: summary.remarks ?? '',
      });
    }
  }, [data]);

  const handleModalClose = () => {
    setIsBillModalOpen(false);
    setIsDiaryModalOpen(false);
  };

  return (
    <form>
      <MainCard>
        <Grid container rowSpacing={2} columnSpacing={1.5} item>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Type"
              fullWidth
              name="type"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={state.type}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Client/Matter"
              fullWidth
              name="clientMatter"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={state.clientMatter}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Bill Attorney"
              fullWidth
              name="billAttorney"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={state.billAttorney}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <StyledDatePicker
              size="small"
              inputFormat="MM/DD/YYYY"
              format="MM/DD/YYYY"
              value={state?.startDate}
              label="Start Date"
              disabled
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <StyledDatePicker
              size="small"
              inputFormat="MM/DD/YYYY"
              format="MM/DD/YYYY"
              value={state?.endDate}
              label="End Date"
              disabled
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Bill Type"
              fullWidth
              name="billType"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={state.billType}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Contact"
              fullWidth
              name="contact"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={state.contact}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssMultiTextField
              label="Address"
              fullWidth
              InputLabelProps={{ shrink: true }}
              rows={2}
              multiline
              disabled
              value={state.address}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Telephone"
              fullWidth
              name="telephone"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={state.telephoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssTextField
              label="Email Address"
              fullWidth
              name="emailAddress"
              disabled
              InputLabelProps={{ shrink: true }}
              size="small"
              value={state.emailAddress}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CssMultiTextField
              label="Remarks"
              fullWidth
              InputLabelProps={{ shrink: true }}
              rows={2}
              multiline
              disabled
              value={state.remarks}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack
              style={{
                flexDirection: 'row',
                gap: '8px',
                float: 'right',
              }}
            >
              <StyledLoadingButton
                onClick={() => setIsDiaryModalOpen(true)}
                variant="outlined"
              >
                View Diary
              </StyledLoadingButton>
              <StyledLoadingButton
                onClick={() => setIsBillModalOpen(true)}
                variant="outlined"
              >
                Billing Details
              </StyledLoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </MainCard>
      <BillingModal
        open={isBillModalOpen}
        handleClose={() => handleModalClose()}
        id={id}
        summaryData={state}
      />
      <DiaryModal
        summaryData={state}
        id={id}
        open={isDiaryModalOpen}
        handleClose={() => handleModalClose()}
      />
    </form>
  );
}
