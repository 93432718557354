import React, { useEffect, useState } from 'react';

import DataTable from '../../../Components/DataGrid';
import { GET_ADVANCE_LEDGER_INQUIRY } from '../../../Graphql/queries/GET_ADVANCE_LEDGER_INQUIRY';
import { Grid } from '@mui/material';
import SummaryPanel from '../../../Components/SummaryPanel';
import TablePanel from '../../../Components/TablePanel';
import dayjs from 'dayjs';
import jsUtils from '../../../utils/jsUtils';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import { useLazyQuery } from '@apollo/client';

const AdvanceLedger = () => {
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);
  const [advanceLedgerList, setAdvanceLedger] = useState([]);

  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (inquiryTarget) {
      let { client, matter, startDate, endDate } = inquiryTarget;

      if (client) {
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (state) {
      fetchAdvanceLedgerInquiry();
    }
  }, [state]);

  const [fetchAdvanceLedgerInquiry] = useLazyQuery(
    GET_ADVANCE_LEDGER_INQUIRY,
    {
      variables: state,
      onCompleted: (data) => {
        if (data.getInquiryLedgerAdvance.inquiryLedgerDto) {
          const ledgerList =
            data.getInquiryLedgerAdvance.inquiryLedgerDto;
          setAdvanceLedger(ledgerList);
          setCount(data.getInquiryLedgerAdvance.count);
          setAmount(data.getInquiryLedgerAdvance.amount);
          setBalance(data.getInquiryLedgerAdvance.balance);
        }
      },
    }
  );

  const columns = [
    {
      field: 'Id',
      headerName: 'ID',
      width: 100,
      isCalcutateIcon: false,
    },
    {
      field: 'ledgerDate',
      headerName: 'Date',
      width: 120,
      valueGetter: (params) =>
        dayjs(params.row.ledgerDate).format('MM-DD-YYYY'),
      isCalcutateIcon: false,
    },
    {
      field: 'ledgerType',
      headerName: 'Type',
      width: 160,
      valueGetter: (params) => params.row.ledgerType,
      isCalcutateIcon: false,
    },
    {
      field: 'clientCode',
      headerName: 'Client',
      width: 130,
      valueGetter: (params) => params.row.clientCode,
      isCalcutateIcon: false,
    },
    {
      field: 'jobCode',
      headerName: 'Matter',
      width: 130,
      valueGetter: (params) => params.row.jobCode,
      isCalcutateIcon: false,
    },
    {
      field: 'payee',
      headerName: 'Payee',
      width: 130,
      valueGetter: (params) => params.row.payee,
      isCalcutateIcon: false,
    },
    {
      field: 'entryPayor',
      headerName: 'Payor',
      width: 130,
      valueGetter: (params) => params.row.entryPayor,
      isCalcutateIcon: false,
    },
    {
      field: 'billNumber',
      headerName: 'Bill',
      width: 130,
      valueGetter: (params) => params.row.billNumber,
      isCalcutateIcon: false,
    },
    {
      field: 'amount',
      width: 140,
      headerName: 'Amount',
      isCalcutateIcon: true,
      valueGetter: (params) =>
        `${jsUtils.negativeCurrencyFormatter(params.row.amount)}`,
    },
    {
      field: 'advanceRetainerAmount',
      width: 160,
      headerName: 'Retainer Amount',
      isCalcutateIcon: true,
      valueGetter: (params) =>
        `${jsUtils.negativeCurrencyFormatter(
          params.row.advanceRetainerAmount
        )}`,
    },
    {
      field: 'balance',
      width: 140,
      headerName: 'Balance',
      isCalcutateIcon: false,
      valueGetter: (params) =>
        `${jsUtils.negativeCurrencyFormatter(params.row.balance)}`,
    },
    {
      field: 'description',
      width: 300,
      headerName: 'Entry Remarks',
      isCalcutateIcon: false,
      valueGetter: (params) => params.row.description,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sx={3} sm={3} md={3} lg={3} xl={2.3}>
          <SummaryPanel
            List={[
              { title: 'Count:', value: count },
              {
                title: 'Amount:',
                value: jsUtils.negativeCurrencyFormatter(amount),
              },
              {
                title: 'Balance(ITD):',
                value: jsUtils.negativeCurrencyFormatter(balance),
              },
            ]}
          />
        </Grid>
        <Grid item sx={9} sm={9} md={9} lg={9} xl={9.7}>
          <TablePanel title="Advance Ledger">
            <DataTable rows={advanceLedgerList} columns={columns} />
          </TablePanel>
        </Grid>
      </Grid>
    </>
  );
};

export default AdvanceLedger;
