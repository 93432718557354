import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyQuery } from '@apollo/client';
import GET_DIARY from '../../../../Graphql/queries/GET_DIARY';
import {
  DiaryCustomTextField
} from '../../../../Components/CommonComponent';
import styled from '@emotion/styled';

const InitialState = {
  MessageText: '',
};
const DiaryModal = ({ open, handleClose, summaryData, id }) => {
  const [state, setState] = useState(InitialState);

  const [fetchClientDiary] = useLazyQuery(GET_DIARY, {
    variables: { id: id?.clientId },
    onCompleted: (data) => {
      if (data.getDiary) {
        setState(data.getDiary);
      } else {
        setState(InitialState);
      }
    },
  });

  const [fetchJobDiary] = useLazyQuery(GET_DIARY, {
    variables: { id: id?.jobId },
    onCompleted: (data) => {
      if (data.getDiary) {
        setState(data.getDiary);
      } else {
        setState(InitialState);
      }
    },
  });
  // eslint-disable-next-line no-unused-vars
  const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: '1px 20px 20px 20px',
    },
  }));

  useEffect(() => {
    if (id?.jobId) {
      fetchJobDiary();
    } else if (id?.clientId) {
      fetchClientDiary();
    }
  }, [id, fetchClientDiary, fetchJobDiary]);

  return (
    <CustomDialog open={open} maxWidth="md" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Diary for: {summaryData?.clientMatter}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#0050c2',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className="space-y-3">
          <Box className="bg-blue-200/50 p-4 flex flex-row items-center space-x-2 rounded-md">
            <AccountCircle
              style={{ fill: '#0ca1e6' }}
              fontSize="large"
              color="blue"
            />
            <Typography variant="subtitle1" fontWeight="regular">
              This diary is a public forum
            </Typography>
          </Box>
          <DiaryCustomTextField
            label="Client Diary"
            fullWidth
            InputLabelProps={{ shrink: true }}
            multiline
            disabled
            value={state?.MessageText}
          />
        </Box>
      </DialogContent>
      {/* Commendted out, will be hidden for the GA release *}
      {/* <DialogActions>
        <Box className="pr-4 pb-4 space-x-4">
          <StyledLoadingButton variant="outlined">
            Preview
          </StyledLoadingButton>
          <StyledLoadingButton
            style={{ backgroundColor: '#0050C3', color: '#fff' }}
            variant="outline"
          >
            Save
          </StyledLoadingButton>
        </Box>
      </DialogActions> */}
    </CustomDialog>
  );
};

export default DiaryModal;
