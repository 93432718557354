import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AutoComplete } from '../../../Components/CommonComponent';
import SEARCH_CLIENT_MATTERS from '../../../Graphql/queries/SEARCH_CLIENT_MATTERS.js';

export default function MatterSearch({
  selectedClient,
  disabled,
  setSelectedMatter,
  selectedMatter
}) {
  const [searchString, setSearchString] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if(selectedClient && !selectedMatter){
      // when selected client value change
      setSelectedMatter(null);
      setSearchString('');

      searchClientMatters();
    }
  }, [selectedClient]);

  // eslint-disable-next-line no-unused-vars
  // Get client matter
  const [searchClientMatters, { loading }] =
    useLazyQuery(SEARCH_CLIENT_MATTERS, {
      variables: {
        searchText: selectedClient? selectedClient.clientCode : searchString,
        searchType: selectedClient ? 'CLIENT' : 'MATTER',
        moduleType: 'INQUIRY',
      },
      onCompleted: (data) => {
        const clientMatters =
          data?.searchClientMattersImpl?.clientMatters ?? [];

        setOptions(clientMatters);
      },
    });

  const isOptionEqualToValue = (option, value) => {
    // Compare the option and value objects
    return option.matterId === value.matterId;
  };

  const handleInputChange = (event, value) => {
    // when input change
    setSearchString(value);
    searchClientMatters();
  };
  const renderOptions = (props,option) => {
    return <li {...props} key={option.matterId}>{option.matterCode} - {option.matterDesc}</li>
  }
  return (
    <AutoComplete
      label="Matter"
      inputValue={searchString}
      options={options}
      loading={loading}
      value={selectedMatter}
      clearOnBlur
      handleHomeEndKeys
      disabled={disabled}
      getOptionLabel={(option) =>
        `${option.matterCode} - ${option.matterDesc}`
      }
     onInputChange={handleInputChange}
      onChange={(event, value) => {
        setSelectedMatter(value);
      }}
      renderOption = {renderOptions}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}
