import React, { useEffect, useState } from 'react';
import { CssTextField } from '../../../Components/CommonComponent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import StyledDatePicker from "../../../Components/CommonComponent/StyledDatePicker";
import { MenuItem } from '@mui/material';

export default function RangePicker({ setSelectedStartDate, setSelectedEndDate, selectedStartDate, selectedEndDate, selectedClient, selectedClientMatter }) {
  const [openType, setOpen] = useState(false);
  const [range, setRange] = useState('Select One');
  const today = dayjs();

  useEffect(() => {
    setRange('Select One');
  }, [selectedClient, selectedClientMatter])

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setRange(selectedValue);
    setOpen(false);

    const dateRanges = {
      mtd: calculateMonthToDate(),
      ytd: calculateYearToDate(),
      itd: calculateInceptionToDate(),
      lm: calculateLastMonth(),
      ly: calculateLastYear(),
    };

    const { start, end } = dateRanges[selectedValue] || {};

    if (start && end) {
      setSelectedStartDate(start);
      setSelectedEndDate(end);
    }
  };

  const calculateMonthToDate = () => {
    const firstDayOfMonth = today.startOf('month');
    const startDate = firstDayOfMonth;
    return { start: startDate, end: today };
  };

  const calculateYearToDate = () => {
    const firstDayOfYear = today.startOf('year');
    const startDate = firstDayOfYear;
    return { start: startDate, end: today };
  };

  const calculateInceptionToDate = () => {
    if(selectedClientMatter){
      return { start: dayjs(selectedClientMatter.jobInceptionDate), end: today };
    }

    return { start: dayjs(selectedClient.clientInceptionDate), end: today };
  };

  const calculateLastMonth = () => {
    const oneMonthAgo = today.subtract(1, 'month').startOf('month');
    const endOfOneMonthAgo = today.subtract(1, 'month').endOf('month');
    return { start: oneMonthAgo, end: endOfOneMonthAgo };
  };

  const calculateLastYear = () => {
    const oneYearAgo = today.subtract(1, 'year').startOf('year');
    const endOfOneYearAgo = today.subtract(1, 'year').endOf('year');
    return { start: oneYearAgo, end: endOfOneYearAgo };
  };

  return (

    <>
      <StyledDatePicker
        size="small"
        inputFormat="MM/DD/YYYY"
        format="MM/DD/YYYY"
        label="Start Date"
        value={selectedStartDate}
        onChange={(date) => {
          setSelectedStartDate(date);
          setRange('Select One');
        }}
        slotProps={{
          textField: {
            InputLabelProps: { shrink: true },
          },
        }}
      />
      <StyledDatePicker
        size="small"
        inputFormat="MM/DD/YYYY"
        format="MM/DD/YYYY"
        label="End Date"
        value={selectedEndDate}
        onChange={(date) => {
          setSelectedEndDate(date);
          setRange('Select One');
        }}
        slotProps={{
          textField: {
            InputLabelProps: { shrink: true },
          },
        }}
      />

      <CssTextField
        label="Range"
        fullWidth
        size="small"
        select
        value={range}
        name="range"
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        color="primary"
        onClick={() => setOpen(!openType)}
        SelectProps={{
          open: openType,
          IconComponent: () =>
            openType ? (
              <KeyboardArrowUpIcon
                className="MuiIcons"
                style={{
                  marginRight: '0.5rem',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                className="MuiIcons"
                style={{
                  marginRight: '0.5rem',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
              />
            ),
        }}
      >
        <MenuItem default value={'Select One'}>Select One</MenuItem>
        <MenuItem value="mtd">Month To Date</MenuItem>
        <MenuItem value="ytd">Year To Date</MenuItem>
        <MenuItem value="itd">Inception To Date</MenuItem>
        <MenuItem value="lm">Last Month</MenuItem>
        <MenuItem value="ly">Last Year</MenuItem>
      </CssTextField>
    </>
  );
}
