import React, { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import DataTable from './DataTable';

import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import { useLazyQuery } from '@apollo/client';
import { GET_CONTACTS_BY_CLIENT_ID } from '../../../Graphql/queries/GET_CONTACTS_BY_CLIENT_ID';
import { GET_CONTACTS_BY_MATTER_ID } from '../../../Graphql/queries/GET_CONTACTS_BY_MATTER_ID';
import './styles.scss';

export default function ContactTab() {
  const [rows, setRows] = useState([]);
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);

  // eslint-disable-next-line no-unused-vars
  // Get Contacts By Client Id
  const [fetchContactsByClientId] =
    useLazyQuery(GET_CONTACTS_BY_CLIENT_ID, {
      variables: {clientId : state?.clientId},
      onCompleted: (data) => {
        const contacts = data?.getContactsByClient?.customerContacts;
        
        if (contacts) {
          const contactsWithIds = contacts.map((row, index) => ({ ...row, id: index + 1 }));
          setRows(contactsWithIds);
        } else {
          setRows([]);
        }
      },
    });

  // eslint-disable-next-line no-unused-vars
  // Get Contacts By Matter Id
  const [fetchContactsByMatterId] =
    useLazyQuery(GET_CONTACTS_BY_MATTER_ID, {
      variables: {jobId : state?.jobId},
      onCompleted: (data) => {
        const contacts = data?.getMatterInfoInquiry;
        
        if (contacts) {
          const contactsWithIds = contacts.map((row, index) => ({ ...row, id: index + 1 }));
          setRows(contactsWithIds);
        } else {
          setRows([]);
        }
      },
    });

  useEffect(() => {
    if (inquiryTarget) {
      // Destructure data
      const { client, matter } = inquiryTarget;

      if (client) {
        // Set states
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : null,
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if(state){
      const {clientId, jobId}  = state;

      // Get Contacts By Matter Id when jobId is available
      if(jobId){
        fetchContactsByMatterId();
      }

      // Get Contacts By Client Id when clientId is available
      else if (clientId) {
        fetchContactsByClientId();
      }
    }
  }, [state]);

  return (
	<Box className="tab-content">
        <Box className="content-header">
          <Typography variant="h6" paddingLeft={1}>
          Contact List
          </Typography>
        </Box>
        <Box>
          <DataTable rows={rows}/>
        </Box>
      </Box>
  );
}
