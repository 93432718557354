import React, { useEffect, useState } from 'react';

import { AutoComplete } from '../../../Components/CommonComponent';
import SEARCH_CLIENT_MATTERS from '../../../Graphql/queries/SEARCH_CLIENT_MATTERS.js';
import { useLazyQuery } from '@apollo/client';

export default function ClientSearch({ setSelectedClient, selectedMatter, selectedClient}) {
  const [searchString, setSearchString] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if(selectedMatter && !selectedClient){
      // when selected client value change
      setSelectedClient(null);
      setSearchString('');

      searchClientMatters();
    }
  }, [selectedMatter]);


  // Get client matter
  const [searchClientMatters, { loading }] =
    useLazyQuery(SEARCH_CLIENT_MATTERS, {
      variables: {
        searchText: selectedMatter ? selectedMatter.matterCode : searchString,
        searchType: selectedMatter ? 'MATTER' : 'CLIENT',
        moduleType: 'INQUIRY',
      },
      onCompleted: (data) => {
        const clientMatters = data?.searchClientMattersImpl?.clientMatters ?? [];

        //remove duplicate clients based on clientId
        const filteredOptions = clientMatters.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((t) => t.clientId === obj.clientId)
        );
        if(selectedMatter) {
          const filtered = filteredOptions.filter((option)=>option.clientId===selectedMatter.clientId);
          setOptions(filtered);
        } else
          setOptions(filteredOptions);
      },
    });

  const isOptionEqualToValue = (option, value) => {
    // Compare the option and value objects
    return option.clientId === value.clientId;
  };

  const handleInputChange = (event, value) => {
    // when input change
    setSearchString(value);
    searchClientMatters();
  };

  return (
    <AutoComplete
      label="Client"
      inputValue={searchString}
      options={options}
      loading={loading}
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={(option) =>
        `${option.clientCode} - ${option.clientDesc}`
      }
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      onChange={(event, value) => {
         setSelectedClient(value);
      }}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}
