import React, { useEffect, useState } from 'react';

import DataTable from '../../../Components/DataGrid';
import { GET_AR_LEDGER_INQUIRY } from '../../../Graphql/queries/GET_AR_LEDGER_INQUIRY';
import { Grid } from '@mui/material';
import SummaryPanel from '../../../Components/SummaryPanel';
import TablePanel from '../../../Components/TablePanel';
import dayjs from 'dayjs';
import jsUtils from '../../../utils/jsUtils';
import { useInquiryTarget } from '../../../hooks/useInquiryTarget';
import { useLazyQuery } from '@apollo/client';

const INITIAL_SUMMARY = [
  {
    title: 'Count :',
    value: 0,
  },
  {
    title: 'Fees :',
    value: '$ 00.00',
  },
  {
    title: 'Expenses :',
    value: '$ 00.00',
  },
  {
    title: 'Surcharges :',
    value: '$ 00.00',
  },
  {
    title: 'Interest :',
    value: '$ 00.00',
  },
  {
    title: 'Taxes :',
    value: '$ 00.00',
  },
  {
    title: 'Total :',
    value: '$ 00.00',
  },
  {
    title: 'Balance(ITD) :',
    value: '$ 00.00',
  },
];

const ARLedgerTab = () => {
  const [rows, setRows] = useState([]);
  const { inquiryTarget } = useInquiryTarget();
  const [state, setState] = useState(null);
  const [Summary, setSummary] = useState(INITIAL_SUMMARY);

// eslint-disable-next-line no-unused-vars
// Get Ledger AR Inquiry
  const [fetchARLedgerInquiry] =
    useLazyQuery(GET_AR_LEDGER_INQUIRY, {
      variables: state,
      onCompleted: (data) => {
        if (data.getLedgerARInquiry.LedgerARInquiryList) {
          setRows(data.getLedgerARInquiry.LedgerARInquiryList);

          setSummary([
            {
              title: 'Count :',
              value:
                data.getLedgerARInquiry.LedgerARInquiryList.length,
            },
            {
              title: 'Fees :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getLedgerARInquiry.FeeAmount
              ),
            },
            {
              title: 'Expenses :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getLedgerARInquiry.ExpenseAmount
              ),
            },
            {
              title: 'Surcharges :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getLedgerARInquiry.SurchargeAmount
              ),
            },
            {
              title: 'Interest :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getLedgerARInquiry.InterestAmount
              ),
            },
            {
              title: 'Taxes :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getLedgerARInquiry.TaxAmount
              ),
            },
            {
              title: 'Total :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getLedgerARInquiry.Amount
              ),
            },
            {
              title: 'Balance(ITD) :',
              value: jsUtils.negativeCurrencyFormatter(
                data.getLedgerARInquiry.Balance
              ),
            },
          ]);
        } else {
          setRows([]);
          setSummary(INITIAL_SUMMARY);
        }
      },
    });

  useEffect(() => {
    if (inquiryTarget) {
      // Destructure data
      let { client, matter, startDate, endDate } = inquiryTarget;

      if (client) {
        // Set states
        setState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
      }
    }
  }, [inquiryTarget]);

  // Fetch AR ledger inquiry when state changes
  useEffect(() => {
    if (state) {
      fetchARLedgerInquiry();
    }
  }, [state]);

  return (
    <Grid container spacing={2} data-testid='AR-ledger-container'>
    <Grid item xs={3} sm={3} md={3} lg={3} xl={2.3}>
      <SummaryPanel List={Summary} data-testid='summary-panel'/>
    </Grid>
    <Grid item xs={9} sm={9} md={9} lg={9} xl={9.7}>
      <TablePanel title="A/R Ledger" data-testid="table-panel">
        <DataTable rows={rows} columns={columns} />
      </TablePanel>
    </Grid>
  </Grid>
  );
};

export default ARLedgerTab;

const columns = [
  {
    field: 'Id',
    headerName: 'ID',
    width: 100,
    isCalcutateIcon: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 120,
    valueGetter: (params) => dayjs(params.row.LedgerDate).format('MM-DD-YYYY'),
    isCalcutateIcon: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 130,
    valueGetter: (params) => params.row.LedgerType,
    isCalcutateIcon: false,
  },
  {
    field: 'bill',
    headerName: 'Bill',
    width: 130,
    valueGetter: (params) => params.row.BillNumber,
    isCalcutateIcon: false,
  },
  {
    field: 'client',
    headerName: 'Client',
    width: 130,
    valueGetter: (params) => params.row.ClientCode,
    isCalcutateIcon: false,
  },
  {
    field: 'matter',
    headerName: 'Matter',
    width: 130,
    valueGetter: (params) => params.row.JobCode,
    isCalcutateIcon: false,
  },
  {
    field: 'fees',
    width: 140,
    headerName: 'Fees',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.FeeAmount),
  },
  {
    field: 'expenses',
    width: 140,
    headerName: 'Expenses',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.ExpenseAmount),
  },
  {
    field: 'interest',
    width: 140,
    headerName: 'Interest',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.InterestAmount),
  },

  {
    field: 'surcharges',
    width: 170,
    headerName: 'Surcharges',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.SurchargeAmount),
  },
  {
    field: 'taxes',
    width: 140,
    headerName: 'Taxes',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.TaxAmount),
  },
  {
    field: 'retainer',
    width: 140,
    headerName: 'Retainer',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.RetainerAmount),
  },
  {
    field: 'total',
    width: 140,
    headerName: 'Total',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.Amount),
  },
  {
    field: 'balance',
    width: 140,
    headerName: 'Balance',
    isCalcutateIcon: true,
    valueGetter: (params) => jsUtils.negativeNumberFormatter(params.row.Balance),
  },
  {
    field: 'remark',
    width: 300,
    headerName: 'Remark',
    isCalcutateIcon: true,
    valueGetter: (params) => params.row.Description,
  },
];
