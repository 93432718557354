export const agedWIPTabState = [
  {
    title: "0 - 30 days :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
  {
    title: "31 - 60 days :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
  {
    title: "61 - 90 days :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
  {
    title: "91 - 120 days :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
  {
    title: "Over 120 days :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
  {
    title: "Total :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
  {
    title: "Less DNC :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
  {
    title: "Net Total :",
    fee: "$ 0.00",
    expense: "$ 0.00",
    total: "$ 0.00",
  },
];

export const activityTabState = [
  {
    title: "Worked :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
  {
    title: "Worked (Billed) :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
  {
    title: "Mark Up / Down :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
  {
    title: "Billed :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
  {
    title: "Discount :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
  {
    title: "Net Billed :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
  {
    title: "Collected :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
  {
    title: "Adjusted :",
    fee_amount: "0.00",
    expense_amount: "0.00",
    fee_rel: "-",
    expense_rel: "-",
  },
];

export const AgedApTabState = [
  {
    title: "0 - 30 days :",
    fee: "-",
    expense: "-",
    total: "-",
  },
  {
    title: "31 - 60 days :",
    fee: "-",
    expense: "-",
    total: "-",
  },
  {
    title: "61 - 90 days :",
    fee: "-",
    expense: "-",
    total: "-",
  },
  {
    title: "91 - 120 days :",
    fee: "-",
    expense: "-",
    total: "-",
  },
  {
    title: "Over 120 days :",
    fee: "-",
    expense: "-",
    total: "-",
  },
  {
    title: "Total :",
    fee: "-",
    expense: "-",
    total: "-",
  },
];

export const onAccountTabState = [
  {
    title: "0 - 30 days :",
    trust: "-",
    advance: "-",
  },
  {
    title: "31 - 60 days :",
    trust: "-",
    advance: "-",
  },
  {
    title: "61 - 90 days :",
    trust: "-",
    advance: "-",
  },
  {
    title: "91 - 120 days :",
    trust: "-",
    advance: "-",
  },
  {
    title: "Over 120 days :",
    trust: "-",
    advance: "-",
  },
  {
    title: "Total :",
    trust: "-",
    advance: "-",
  },
];
