import { combineReducers } from '@reduxjs/toolkit';

// if you have any store in your store import it and spread it in the combinreducers

const createReducer = asyncReducers => (state, action) => {
	const combinedReducer = combineReducers({
		...asyncReducers
	});

	/*
	Reset the redux store when user logged out
	 */
	if (action.type === 'auth/user/userLoggedOut') {
		state = undefined;
	}

	return combinedReducer(state, action);
};

export default createReducer;
