import React from 'react';
import "./style.scss";
import { Typography } from '@mui/material';

export default function SummaryPanel({ List = [] }) {
  return (
    <div className='summary-panel'>
      <div className='summary-panel-header'>
        <Typography variant="h6" className='summary-panel-title'>
          Summary
        </Typography>
      </div>
      <div className='summary-panel-content'>
        {
          List.length > 0 && List.map((item, index) => {
            return (
              <div key={index} className='summary-panel-items'>
                <Typography variant='div'>
                  {item.title}
                </Typography>
                <Typography variant='h6' style={{fontWeight : index === List.length - 1 ? 500 : 400}}>
                  {item.value}
                </Typography>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
