import { gql } from "@apollo/client";

export const GET_CLIENT_ATTORNEYS = gql`
  query GetClientRecords($id: Int!) {
    getClientRecords(inquiryRequest: { id: $id }) {
      originators {
        id
        employeeID
        code
        description
        originationPercent
        accountingPeriodStart {
          id
          code
          description
          startDate
          endDate
          year
          period
          lockFee
          lockExpense
          status {
            id
            status
          }
        }
        accountingPeriodEnd {
          id
          code
          description
          startDate
          endDate
          year
          period
          lockFee
          lockExpense
          status {
            id
            status
          }
        }
      }
      responsibles {
        id

        employeeID
        code
        description
        responsiblePercent
        accountingPeriodStart {
          id
          code
          description
          startDate
          endDate
          year
          period
          lockFee
          lockExpense
          status {
            id
            status
          }
        }
        accountingPeriodEnd {
          id
          code
          description
          startDate
          endDate
          year
          period
          lockFee
          lockExpense
          status {
            id
            status
          }
        }
      }
      relationships {
        id
        code
        description

        employeeID
        managerPercent
        accountingPeriodStart {
          id
          code
          description
          startDate
          endDate
          year
          period
          lockFee
          lockExpense
          status {
            id
            status
          }
        }
        accountingPeriodEnd {
          id
          code
          description
          startDate
          endDate
          year
          period
          lockFee
          lockExpense
          status {
            id
            status
          }
        }
      }
    }
  }
`;
