import { gql } from '@apollo/client';

const SEARCH_CLIENT_MATTERS = gql`
  query SearchClientMatters(
    $searchText: String!
    $searchType: String!
    $moduleType: String!
  ) {
    searchClientMattersImpl(
      searchText: $searchText
      searchType: $searchType
      moduleType: $moduleType
    ) {
      clientMatters {
        clientId
        clientCode
        clientDesc
        matterId
        matterCode
        matterDesc
        description
        jobInceptionDate,
        clientInceptionDate
      }
    }
  }
`;

export default SEARCH_CLIENT_MATTERS;
