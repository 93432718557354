import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TabNavigator = ({ tabPanels, tabsOption, scrollButtons = false }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        height: "50px",
        marginTop: "8px",
      }}
    >
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={scrollButtons}
        aria-label="simple tabs example"
        sx={{
          mb: 3,
          "& button": {
            display: "flex",
            flexDirection: "row",
            minHeight: "50px",
            color: "#00000099",
            textTransform: "capitalize",
            gap: "8px",
          },
          "& button.Mui-selected": {
            color: "#1976d2",
            borderRadius: "8px 8px 0 0",
            "& svg": {
              color: "#1976d2",
            },
          },
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            opacity: 1,
          },
          "& .MuiTabs-scrollButtons > svg": {
            color: "#00000099 !important",
          },
          "& button > svg": {
            marginBottom: "0px !important",
            color: "#00000099",
          },
        }}
      >
        {tabsOption.map((tab, index) => (
          <Tab key={index} icon={tab.icon} label={tab.label} />
        ))}
      </Tabs>

      {tabPanels.map((panel) => {
        return (
          <TabPanel key={panel.value} value={value} index={panel.value}>
            {panel.component}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default TabNavigator;
