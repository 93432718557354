import PropTypes from 'prop-types';
import React, { useState } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Typography} from '@mui/material';

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
    (
        {
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            secondary,
            sx = {},
            title,
            isOpenFromModel,
            ...others
        },
        ref
    ) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    borderRadius: '8px',
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px 0.5px rgba(0, 0, 0, 0.20)',
                    // borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                    // color : "red",
                    // ':hover': {
                    //     boxShadow: boxShadow
                    //         ? shadow ||
                    //           (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                    //         : 'inherit'
                    // },
                    ...sx
                }}
            >
                {/* card header and action */}
                {/* {title && <CardHeader sx={{backgroundColor: "white", color: "#f0a830"}}  title={title} action={secondary} />} */}
                {title && (
                    <CardHeader sx={headerStyle}
                        className={(!isOpenFromModel && !isHovered) ? 'allow_drag' : ""}
                        title={<div >
                            <Typography
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={styleTypography}
                            >
                                {title}
                            </Typography>
                        </div>
                        }
                        action={<div
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {secondary}
                        </div>
                        }
                    >
                    </CardHeader>
                )}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

const styleTypography = {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
    width: "fit-content",
}

const headerStyle = {
    backgroundColor: "white",
    paddingBottom: '3px',
    paddingTop: '9px'
}

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    button: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};
MainCard.displayName = "MainCard";

export default MainCard;
