import { gql } from '@apollo/client';

const GET_JOB_BILL_SUMMARY = gql`
  query GetJobBillSummary($id: Int!) {
    getJobBillSummary(inquiryRequest: { id: $id }) {
      billThresholds {
        id
        createdByID
        createdDate
        modifiedByID
        modifiedDate
        active
        code
        description
        aliasName
        remarks
        isVisible
        address
        billable
        billTypeID
        jobTypeID
        parentID
        startDate
        endDate
        taxScheduleID
        interestScheduleID
        employeeScheduleID
        taskScheduleID
        expenseScheduleID
        supportsFeeEntry
        supportsExpenseEntry
        requireTaskCode
        requireActivityCode
        draftBillDate
        enforceBillableFlag
        roundingFactor
        jobOnDemand
        expenseCodeSummarize
        hasBills
        limitTaskCode
        limitActivityCode
        limitExpenseCode
        schemeType1
        flatFeeProrateType1
        flatFeeAmount1
        flatFeeExpenseType1
        schemeType2
        flatFeeProrateType2
        flatFeeAmount2
        flatFeeExpenseType2
        billSchemeName
        billSchemeDescription
        billSchemeResetFrequency
        startPeriodID
        matterRemarks
        evergreenMinimumBalance
        evergreenThreshold
        maxFeeTotalAmount
        maxFeeBillAmount
        maxExpenseTotalAmount
        maxExpenseBillAmount
        billSplitTypeID
        maxBillAmount
        maxTotalAmount
        surchargeBillPercent
        discountBillPercent
        billOnHold
        contactID
        employeeScheduleDate
        employeeScheduleNote
        taskScheduleDate
        taskScheduleNote
        expenseScheduleDate
        expenseScheduleNote
        interestScheduleDate
        interestScheduleNote
        taxScheduleDate
        taxScheduleNote
        feeAgreement
        engagementLetter
        conflictWaiver
        geoLocationID
        fileLocation
        jurisdictionID
        invoicePaymentTypeID
        customerBillID
        aliasName2
        taskCodeGroupID
        activityCodeGroupID
        geoLocationGroupID
        expenseCodeGroupID
        requireGeoLocation
        maxDailyHours
        supportsBudgetJob
        defaultBudgetJobPhaseID
        enforceBudgetJobPhase
        isSplitJob
        enforceParentCustomerRole
        budgetSubmitted
        billJobSplitTypeID
        referralSourceID
        referralTypeID
        referralNote
        convertedDate
      }
      billDetails {
        id
        activityCodeXRefID
        administratorEmployeeID
        allowMultipayor
        arStatementMethodType
        arStatementReportID
        billExpenseSequenceTypeID
        billFeeSequenceTypeID
        billFrequencyID
        billInstruction
        billOnDemand
        billRemarks
        code
        collectionEmployeeID
        coverPageReportID
        draftBillReportID
        ebhOverride
        ebhPayor
        ebhPayorName
        eBillField01
        eBillField02
        eBillField03
        eBillField04
        eBillField05
        eBillField06
        eBillField07
        eBillField08
        eBillField09
        eBillField10
        eBillSystemNameID
        employeeXRefID
        expenseCodeXRefID
        expenseInstruction
        feeInstruction
        finalBillReportID
        lockBillOnGeneration
        maximumUnbilledExpense
        maximumUnbilledFee
        maximumWIPAge
        minimumUnbilledExpense
        minimumUnbilledFee
        parentID
        printARStatement
        printCoverPage
        supervisorEmployeeID
        taskCodeXRefID
      }
      billFrequency {
        id
        Description
      }
      draftBillReport {
        ID
        description
      }
      coverPageReport {
        ID
        description
      }
      arStatementReport {
        ID
        description
      }
      finalBillReport {
        ID
        description
      }
      taxSchedule {
        ID
        Code
      }
      interestSchedule {
        ID
        Code
      }
      employeeSchedule {
        ID
        Code
      }
      taskSchedule {
        ID
        Code
      }
      expenseSchedule {
        ID
        Code
      }
    }
  }
`;

export default GET_JOB_BILL_SUMMARY;
