import React, { useState, useEffect } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import jsUtilsSubTab from "./utils/jsUtils";
import { agedWIPTabState } from "./utils/initialStates";
import jsUtils from "../../../../utils/jsUtils";

const { calculateAgedRow, calculateAgedWIPNetTotal } = jsUtilsSubTab;

const AgedWIPTab = ({ data }) => {
  const [rows, setRows] = useState(agedWIPTabState);

  useEffect(() => {
    if (data) {
      // Set rows
      setRows(prevRows => {
        const updatedRows = prevRows.map(row => {
          switch (row.title) {
            case "0 - 30 days :":
              return calculateAgedRow(data.feeAmountAge1, data.expenseAmountAge1, row);
            case "31 - 60 days :":
              return calculateAgedRow(data.feeAmountAge2, data.expenseAmountAge2, row);
            case "61 - 90 days :":
              return calculateAgedRow(data.feeAmountAge3, data.expenseAmountAge3, row);
            case "91 - 120 days :":
              return calculateAgedRow(data.feeAmountAge4, data.expenseAmountAge4, row);
            case "Over 120 days :":
              return calculateAgedRow(data.feeAmountAge5, data.expenseAmountAge5, row);
            case "Total :":
              return calculateAgedRow(data.feeAmountTotal, data.expenseAmountTotal, row);
            case "Less DNC :":
              return calculateAgedRow(data.feeAmountDNC, data.expenseAmountDNC, row);
            default:
              return row;
          }
        });

        updatedRows[updatedRows.length - 1] = calculateAgedWIPNetTotal(updatedRows);

        return updatedRows;
      })
    }
  }, [data])

  return (
    <Box className="summary-table-content">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Aged WIP</TableCell>
            <TableCell align="right">Fees</TableCell>
            <TableCell align="right">Expenses</TableCell>
            <TableCell align="right">Total *</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="left">{item.title}</TableCell>
                <TableCell align="right">{jsUtils.negativeCurrencyFormatter(item.fee)}</TableCell>
                <TableCell align="right">{jsUtils.negativeCurrencyFormatter(item.expense)}</TableCell>
                <TableCell align="right">{jsUtils.negativeCurrencyFormatter(item.total)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box className="info-content-box">*DNC - Timekeepers can mark entries as Do Not Charge while the entry is still in Work in Process. The Net Total reflects the totals with the Do Not Charge entries removed.</Box>
    </Box>
  );
};

export default AgedWIPTab;
