import { gql } from '@apollo/client';

const GET_CLIENT_ACTIVITY_FEE = gql`
  query getClientActivityFee($clientId: Int!, $jobId: Int!, $startDate: String!, $endDate: String!) {
    getClientActivityFee(clientActivityFeeRequest: {
		clientId: $clientId,
		jobId: $jobId,
		startDate: $startDate,
		endDate: $endDate
	  }) {
		clientActivityFee {
		id
		activityTypeId
		activityDate
		statusId
		status
		billableTypeId
		billable
		customerId
		clientCode
		jobCode
		clientDescription
		jobDescription
		employeeId
		employeeCode
		employeeDescription
		taskCodeId
		taskCode
		activityCodeId
		activityCode
		hours
		quantity
		rate
		amount
		billedHours
		billedQuantity
		billedRate
		billedAmount
		billedDiscount
		billedNetDiscount
		description
		billId
		writeOff
		doNotCharge
		}
    }
}`

export default GET_CLIENT_ACTIVITY_FEE;