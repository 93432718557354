import { gql } from '@apollo/client';

export const GET_EXPENSE_INQUIRY = gql`
  query GetExpenseInquiry(
    $clientId: Int!
    $jobId: Int
    $startDate: String!
    $endDate: String!
  ) {
    getExpenseInquiry(
      clientId: $clientId
      jobId: $jobId
      startDate: $startDate
      endDate: $endDate
    ) {
      Id
      ActivityTypeID
      ActivityDate
      StatusID
      Status
      BillableTypeID
      Billable
      CustomerID
      ClientCode
      JobCode
      ClientDescription
      JobDescription
      EmployeeID
      EmployeeCode
      EmployeeDescription
      ExpenseCodeID
      ExpenseCode
      Hours
      Quantity
      Rate
      Amount
      BilledQuantity
      BilledRate
      BilledAmount
      BilledDiscount
      BilledNetDiscount
      Description
      BillID
      WriteOff
      DoNotCharge
      InvoiceID
      VendorCode
      VendorDescription
    }
  }
`;
