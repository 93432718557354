import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CustomTable from "../../../../Components/Table";
import "./style.scss";
import jsUtils from "../../../../utils/jsUtils";

const Summary = ({ data }) => {

    const [balanceRow, setBalanceRow] = useState(initialBalanceRow);
    const [productivityRow, setProductivityRow] = useState(initialProductivityRows);

    useEffect(() => {
        if (!data) return;

        const { agedWIP, agedAR, clientTrust, matterTrust, clientAdvance, matterAdvance, balances } = data;
        let NET_TOTAL_PROCESS = 0.0;
        let NET_TOTAL_BILLED = 0.0;

        // Set Balance row
        setBalanceRow([
            { ...balanceRow[0], total: jsUtils.negativeCurrencyFormatter(agedWIP[0]?.feeAmountTotal + agedWIP[0]?.expenseAmountTotal) },
            { ...balanceRow[1], total: jsUtils.negativeCurrencyFormatter(agedAR[0]?.arAmountTotal) },
            {
                ...balanceRow[2],
                client: jsUtils.negativeCurrencyFormatter(clientTrust[0]?.clientTrustTotal),
                matter: jsUtils.negativeCurrencyFormatter(matterTrust[0]?.matterTrustTotal),
                total: jsUtils.negativeCurrencyFormatter((clientTrust[0]?.clientTrustTotal || 0) + (matterTrust[0]?.matterTrustTotal || 0))
            },
            {
                ...balanceRow[3],
                client: jsUtils.negativeCurrencyFormatter(clientAdvance[0]?.clientAdvanceTotal),
                matter: jsUtils.negativeCurrencyFormatter(matterAdvance[0]?.matterAdvanceTotal || 0),
                total: jsUtils.negativeCurrencyFormatter((clientAdvance[0]?.clientAdvanceTotal || 0) + (matterAdvance[0]?.matterAdvanceTotal || 0)) 
            }
        ]);

        let PRODUCTIVITY_ROW = [
            {
                ...productivityRow[0],
                worked: jsUtils.negativeCurrencyFormatter(balances[0]?.workedFeeAmount),
                process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillFeeAmount),
                billed: jsUtils.negativeCurrencyFormatter(balances[0]?.billedFeeAmount)
            },
            {
                ...productivityRow[1],
                worked: jsUtils.negativeCurrencyFormatter(balances[0]?.workedExpenseAmount),
                process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillExpenseAmount),
                billed: jsUtils.negativeCurrencyFormatter(balances[0]?.billedExpenseAmount)
            },
            { ...productivityRow[2], process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillSurchargeAmount), billed: jsUtils.negativeCurrencyFormatter(balances[0]?.billedSurchargeAmount) },
            { ...productivityRow[3], process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillInterestAmount), billed: jsUtils.negativeCurrencyFormatter(balances[0]?.billedInterestAmount) },
            { ...productivityRow[4], process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillTaxAmount), billed: jsUtils.negativeCurrencyFormatter(balances[0]?.billedTaxAmount) },
            { ...productivityRow[5], process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillRetainerAmount), billed: jsUtils.negativeCurrencyFormatter(balances[0]?.billedRetainerAmount) },
            {
                ...productivityRow[6],
                worked: jsUtils.negativeCurrencyFormatter((balances[0]?.workedFeeAmount || 0) + (balances[0]?.workedExpenseAmount || 0)),
                process: jsUtils.negativeCurrencyFormatter((balances[0]?.onBillFeeAmount || 0) + (balances[0]?.onBillExpenseAmount || 0) + (balances[0]?.onBillSurchargeAmount || 0) + (balances[0]?.onBillInterestAmount || 0) + (balances[0]?.onBillTaxAmount || 0) + (balances[0]?.onBillRetainerAmount || 0)),
                billed: jsUtils.negativeCurrencyFormatter((balances[0]?.billedFeeAmount || 0) + (balances[0]?.billedExpenseAmount || 0) + (balances[0]?.billedSurchargeAmount || 0) + (balances[0]?.billedInterestAmount || 0) + (balances[0]?.billedTaxAmount || 0) + (balances[0]?.billedRetainerAmount || 0))
            },
            { ...productivityRow[7] },
            { ...productivityRow[8], billed: jsUtils.negativeCurrencyFormatter(balances[0]?.receipt) },
            { ...productivityRow[9], billed: jsUtils.negativeCurrencyFormatter(balances[0]?.adjusted) },
            { ...productivityRow[10], process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillTrustApplied), billed: jsUtils.negativeCurrencyFormatter(balances[0]?.trustApplied) },
            { ...productivityRow[11], process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillAdvanceApplied), billed: jsUtils.negativeCurrencyFormatter(balances[0]?.advanceApplied) },
            { ...productivityRow[12], process: jsUtils.negativeCurrencyFormatter(balances[0]?.onBillRetainerApplied), billed: jsUtils.negativeCurrencyFormatter(balances[0]?.retainerApplied) },
            { ...productivityRow[13],},
        ]

        //Calulating Net Total, = Sub total - (Less Payment,Receipts,Adjustments,Trust,Advance,Retainer Applied)
        PRODUCTIVITY_ROW.slice(6, -1).forEach(row => {

            const processValue = parseFloat(row.process !== "-" ? row.process.replace(/[^0-9.-]/g, '') : 0);
            const billedValue = parseFloat(row.billed !== "-" ? row.billed.replace(/[^0-9.-]/g, '') : 0);

            NET_TOTAL_PROCESS -= NET_TOTAL_PROCESS === 0.0 ? -processValue : processValue;
            NET_TOTAL_BILLED -= NET_TOTAL_BILLED === 0.0 ? -billedValue : billedValue;
        });

        PRODUCTIVITY_ROW[13].process = jsUtils.negativeCurrencyFormatter(NET_TOTAL_PROCESS);
        PRODUCTIVITY_ROW[13].billed = jsUtils.negativeCurrencyFormatter(NET_TOTAL_BILLED);

        setProductivityRow(PRODUCTIVITY_ROW);

    }, [data]);



    return (
        <div className="subtab-summary-container inquery-custom-scroll">
            <Box className="summary-table-content">
                <CustomTable
                    List={balanceRow}
                    columnConfig={balanceColumnConfig}
                />
            </Box>
            <Box className="summary-table-content">
                <CustomTable
                    List={productivityRow}
                    columnConfig={productivityColumnConfig}
                />
            </Box>
        </div>
    );
};

export default Summary;



//default rows 
const initialBalanceRow = [
    { balance: "WIP Balance :", client: "-", matter: "-", total: "-" },
    { balance: "A/R Balance :", client: "-", matter: "-", total: "-" },
    { balance: "Trust Balance :", client: "-", matter: "-", total: "-" },
    { balance: "Advance Balance :", client: "-", matter: "-", total: "-" },
];

const initialProductivityRows = [
    { productivity: "Fees :", worked: "-", process: "-", billed: "-" },
    { productivity: "Expenses :", worked: "-", process: "-", billed: "-" },
    { productivity: "Surcharge :", worked: "-", process: "-", billed: "-" },
    { productivity: "Interest :", worked: "-", process: "-", billed: "-" },
    { productivity: "Taxes :", worked: "-", process: "-", billed: "-" },
    { productivity: "Retainer :", worked: "-", process: "-", billed: "-" },
    { productivity: "Subtotal :", worked: "-", process: "-", billed: "-" },
    { productivity: "Less Payment :", worked: "-", process: "-", billed: "-" },
    { productivity: "Receipts :", worked: "-", process: "-", billed: "-" },
    { productivity: "Adjustments :", worked: "-", process: "-", billed: "-" },
    { productivity: "Trust :", worked: "-", process: "-", billed: "-" },
    { productivity: "Advance :", worked: "-", process: "-", billed: "-" },
    { productivity: "Retainer Applied :", worked: "-", process: "-", billed: "-" },
    { productivity: "Net Total :", worked: "-", process: "-", billed: "-" },
];

//columns header

const balanceColumnConfig = [
    { name: "Balance ITD", align: "left", col: "balance" },
    { name: "Client", align: "right", col: "client" },
    { name: "Matter", align: "right", col: "matter" },
    { name: "Total", align: "right", col: "total" },
]

const productivityColumnConfig = [
    { name: "Productivity ITD", align: "left", col: "productivity" },
    { name: "Worked", align: "right", col: "worked" },
    { name: "Bill in Process", align: "right", col: "process" },
    { name: "Billed", align: "right", col: "billed" },
]