import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledButton = styled(LoadingButton)`
    text-transform: none;
    margin-top: 0.5rem; 
    font-size: 11px;
    line-height: 20px;
    height: 30px;  
    border-radius: 4px;
    font-weight: 500;
`;

export default StyledButton;