import { gql } from '@apollo/client';

const FETCH_SUMMARY_SUBTABS_DATA = gql`
  query GetInquirySummary(
    $clientId: Int!
    $jobId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getInquirySummary(
      summaryRequest: {
        clientId: $clientId
        jobId: $jobId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      client
      matter
      billAttorney
      billTypeSplit
      billTypeMultipayor
      summary {
        id
        code
        description
        startDate
        endDate
        contactFirstName
        contactLastName
        companyName
        type
        addressType
        address
        telephoneType
        telephoneNumber
        extension
        emailAddressType
        emailAddress
        fileLocation
        remarks
      }
      activity {
        hoursWorked
        hoursWorkedBilled
        hoursBilled
        hoursVariance
        hoursBilledRealization
        feeWorked
        feeWorkedBilled
        feeVariance
        feeBilled
        feeDiscount
        feeNetDiscount
        feeBilledRealization
        feeNetDiscountRealization
        feeCollected
        feeCollectedRealization
        feeAdjusted
        expenseWorked
        expenseWorkedBilled
        expenseBilled
        expenseVariance
        expenseDiscount
        expenseNetDiscount
        expenseBilledRealization
        expenseNetDiscountRealization
        expenseCollected
        expenseCollectedRealization
        expenseAdjusted
      }
      agedWIP {
        feeAmountTotal
        expenseAmountTotal
        feeAmountAge1
        feeAmountAge2
        feeAmountAge3
        feeAmountAge4
        feeAmountAge5
        feeAmountDNC
        expenseAmountAge1
        expenseAmountAge2
        expenseAmountAge3
        expenseAmountAge4
        expenseAmountAge5
        expenseAmountDNC
      }
      agedAR {
        feeAmountAge1
        feeAmountAge2
        feeAmountAge3
        feeAmountAge4
        feeAmountAge5
        feeAmountTotal
        expenseAmountAge1
        expenseAmountAge2
        expenseAmountAge3
        expenseAmountAge4
        expenseAmountAge5
        expenseAmountTotal
        arAmountAge1
        arAmountAge2
        arAmountAge3
        arAmountAge4
        arAmountAge5
        arAmountTotal
      }
      matterTrust {
        trustAmountAge1
        trustAmountAge2
        trustAmountAge3
        trustAmountAge4
        trustAmountAge5
        trustAmountTotal
        clientTrustTotal
        matterTrustTotal
      }
      matterAdvance {
        advanceAmountAge1
        advanceAmountAge2
        advanceAmountAge3
        advanceAmountAge4
        advanceAmountAge5
        advanceAmountTotal
        clientAdvanceTotal
        matterAdvanceTotal
      }
      clientTrust {
        trustAmountAge1
        trustAmountAge2
        trustAmountAge3
        trustAmountAge4
        trustAmountAge5
        trustAmountTotal
        clientTrustTotal
        matterTrustTotal
      }
      clientAdvance {
        advanceAmountAge1
        advanceAmountAge2
        advanceAmountAge3
        advanceAmountAge4
        advanceAmountAge5
        advanceAmountTotal
        clientAdvanceTotal
        matterAdvanceTotal
      }
      statistics {
        trustBalance
        advanceBalance
        lastTimeEntry
        lastTimekeeper
        lastExpenseEntry
        lastExpenseCode
        lastBillDate
        lastBillAmount
        lastPaymentDate
        lastPaymentAmount
      }
      cashCycleChart {
        monthDesc
        yearId
        daysToBill
        daysToPay
        daysToCollect
      }
      balances {
        workedFeeAmount
        workedExpenseAmount
        onBillFeeAmount
        onBillExpenseAmount
        onBillTaxAmount
        varianceFeeAmount
        varianceExpenseAmount
        billedFeeAmount
        billedExpenseAmount
        billedTaxAmount
        onBillSurchargeAmount
        onBillInterestAmount
        billedSurchargeAmount
        billedInterestAmount
        onBillRetainerAmount
        billedRetainerAmount
        retainerApplied
        onBillRetainerApplied
        receipt
        adjusted
        trustApplied
        advanceApplied
        onBillTrustApplied
        onBillAdvanceApplied
      }
      timekeeperRecaps {
        code
        hours
        rate
        amount
      }
    }
  }
`;

export default FETCH_SUMMARY_SUBTABS_DATA;
